/* eslint no-console:0 */

import 'common'
import '@react/data_display'
import WebpackerSvelte from 'webpacker-svelte'

import '@svelte/common/App.scss'
import common from '@svelte/common'

import CancelPage from '@svelte/cancel_page/CancelPage.svelte'
import HouseChurchRegistrationForm from '@svelte/house_church_registration/Form.svelte'
import invitation_response from '@svelte/invitation_response'

// Test
import test from '@svelte/test'

// console.log('application.ts', WebpackerSvelte.registeredComponents)
WebpackerSvelte.setup({
  ...common,

  CancelPage,
  HouseChurchRegistrationForm,
  ...invitation_response,
  ...test,
})
// console.log('application.ts: finished: registeredComponents', WebpackerSvelte.registeredComponents)
//
