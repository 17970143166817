<!-- svelte-ignore a11y-no-onchange -->
<select on:input on:blur on:change bind:value {...$$restProps}>
  <option value="" />
  {#each countries || [] as country}
    <option value={country.code}>{country.name}</option>
  {:else}
    <option value="">Loading...</option>
    <!-- <Icon class="material-icons" style="margin-right: 12px;">favorite</Icon> -->
  {/each}
</select>

<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount } from 'svelte';
import axios from 'axios';
export let value;
let countries = [];
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield axios.get(`/countries`);
    countries = yield result.data;
}));
</script>
