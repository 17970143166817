import axios from 'axios'

// https://www.npmjs.com/package/axios#interceptors
axios.interceptors.request.use((request) => {
  function extractParam(key) {
    const match = window.location.search.match(new RegExp(`(?:\\b)${key}=(\\w*)(?:\\b|$)`))
    if (match) {
      return match[1]
    }
  }

  const newParams = ['embed', 'subset']
    .reduce((array, key) => {
      const value = extractParam(key)
      if (value) {
        array.push(`${key}=${value}`)
      }
      return array
    }, [])
    .join('&')

  if (!request.url) {
    return request
  }

  const sep = request.url.includes('?') ? '&' : '?'
  request.url = `${request.url}${sep}${newParams}`
  return request
})
