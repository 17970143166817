<!-- svelte-ignore a11y-click-events-have-key-events -->
<fieldset class="other_people_to_subscribe">
  <p>
    I would like the following people (for example, house church leaders, members, and others who might be interested)
    to receive the RIPPLES newsletter as well:
  </p>
  <!-- <pre class="debug">errors: {JSON.stringify(state.errors, null, 2)}</pre> -->
  <!-- svelte-ignore a11y-missing-attribute -->
  <table class="table table-striped">
    <thead>
      <tr>
        <th>First name</th>
        <th>Last name</th>
        <th>E-mail address</th>
        <th class="actions" />
      </tr>
    </thead>
    {#each $fields.names() as name, i}
      <!-- {name}: {JSON.stringify($fields.value[i])} -->

      <tbody class="">
        <tr class="recipient even" data-i={i}>
          <td class="given_name">
            <div class="field">
              <Field name="{name}[given_name]" validate={maybeRequired} let:field>
                <InputGroup {field} type="text" placeholder="First name" />
              </Field>
            </div>
          </td>
          <td class="surname">
            <div class="field">
              <Field name="{name}[surname]" validate={maybeRequired} let:field>
                <InputGroup {field} type="text" placeholder="Last name" />
              </Field>
            </div>
          </td>
          <td class="email">
            <div class="field">
              <Field name="{name}[email]" validate={maybeRequired} let:field>
                <InputGroup {field} type="email" placeholder="E-mail address" />
              </Field>
            </div>
          </td>
          <td class="actions">
            {#if isRemovable}
              <a role="button" tabindex="0" on:click={() => $fields.remove(i)}> <i class="icon-trash" /> </a>
            {/if}
          </td>
        </tr>
      </tbody>
    {/each}
  </table>

  <p>
    <a on:click={addRow} class="btn" id="subscriptions--add-another" role="button" tabindex="0">
      <i class="icon-plus" />
      Add another row
    </a>
  </p>

  {#if utils.debug || utils.dev}
    <pre class="debug">{JSON.stringify($fields.value)}</pre>
  {/if}
</fieldset>

<script lang="ts">// Known issues:
// Why does the validate function for given_name:
// - not get triggered immediately after the first character is entered in email field? It seems to lag behind by 1 key stroke.
// - not remove the 'is required' if it was added by adding a 2nd row and then we remove the 2nd row?
import 'svelte';
import { utils } from '../../common/utils';
import { required } from 'common/final-form';
import { Field, useFieldArray } from 'svelte-final-form';
import '@material-ui/core';
// import {InputGroup} from '@svelte/common/form/svelte-final-form'
import InputGroup from '@svelte/common/form/svelte-final-form/InputGroup.svelte';
const fieldName = 'subscriptions';
const { fields, meta } = useFieldArray(fieldName);
// TODO: Why does this output "1" 2 times? The comparable example, examples/src/examples/arrays/Arrays_UseFieldArray.svelte, only outputs it 1 time.
// $: console.log($fields.length)
$: isRemovable = $fields.length > 1;
$: subscriptions = $fields.value;
// $: console.log(subscriptions)
$: anyEmailEntered = !!subscriptions.find((_) => _.email && _.email.length > 0);
// $: console.log('anyEmailEntered', anyEmailEntered)
$: isRequired = isRemovable || anyEmailEntered;
// $: console.log('isRequired', isRequired)
// This just makes it conditional on isRequired
$: maybeRequired = (value) => {
    // console.log('maybeRequired:', isRequired, value)
    return isRequired && required(value);
};
const addRow = () => {
    $fields.push({});
};
if ($fields.length === 0) {
    addRow();
}
</script>
