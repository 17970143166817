/*
// Test cases:
flattenErrors('invitation', {'recipient.email': ['is invalid']})
=>
{
  "invitation.recipient.email": [
    "is invalid"
  ]
}

flattenErrors('invitation', {'recipient.name': 'is not cool enough', 'recipient.email': 'is invalid'})
=>
{
  "invitation.recipient.name": "is not cool enough",
  "invitation.recipient.email": "is invalid"
}"

flattenErrors('invitations', [
  {'recipient.email': ['is invalid']}
])
=>
{"invitations[0].recipient.email":["is invalid"]}
// setError('invitations[0].recipient.email', ['is invalid'])

flattenErrors('', {'recipient.email': ['is invalid']})
=> {"recipient.email":["is invalid"]}

flattenErrors('invitations', [{'recipient.name': 'is not cool enough', 'recipient.email': 'is invalid'}])
=>
{
  "invitations[0].recipient.name": "is not cool enough",
  "invitations[0].recipient.email": "is invalid"
}
*/
export function flattenErrors(fieldPrefix, errors) {
  if (!errors) {
    return {}
  }
  const newEntries = Object.entries(errors).map(([key, fieldErrors]) => {
    // For this single entry, return an array of 1 or more entries to replace it with
    // flattenKey will handle:
    // - key will be a string if errors is an object
    // - key should be a number (the index within the array) if errors is an array
    const errorsIsObject = !(Array.isArray(fieldErrors) || typeof fieldErrors === 'string')
    if (errorsIsObject) {
      // Keep recursing if it is an object.
      return Object.entries(flattenErrors(flattenKey(fieldPrefix, key), fieldErrors))
    } else {
      // Stop recursion if fieldErrors is a string (error message) or array (presumably of strings).
      return [[flattenKey(fieldPrefix, key), fieldErrors]]
    }
  })
  return Object.fromEntries(newEntries.flat())
}
;(window as any).flattenErrors = flattenErrors

export function flattenKey(prefix, key) {
  if (Number.isNaN(parseInt(key))) {
    // regular, string key
    if (prefix) {
      return `${prefix}.${key}`
    } else {
      return key
    }
  } else {
    // number key (index within an array field)
    return `${prefix}[${key}]`
  }
}
