import * as React from 'react'
import { HouseChurchBaseSearchResultProps, HouseChurchBaseSearchResult } from './HouseChurchBaseSearchResult'

export interface SimpleChurchSearchResultProps {
  icon_file_name: string
  name: string
  state: string
  human_state: string
  address?: any
  default_conference?: any
  leader_names_for_search?: string
  coach?: any
}

// This is used for showing *network* simple churches in the search results
// See also app/javascript/@react/search/HouseChurchSearchResult.tsx
export function SimpleChurchSearchResult({ ...props }: SimpleChurchSearchResultProps) {
  return <HouseChurchBaseSearchResult {...props} containerClass="simple_church" imgAlt="Simple church" />
}
