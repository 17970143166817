<Form {onSubmit} {initialValues} mutators={{ ...arrayMutators }} let:form let:state>
  <form on:submit|preventDefault={form.submit}>
    <FieldArray name="users" let:fields let:meta>
      <!-- {JSON.stringify(fields.names())} -->
      {#each fields.names() as name, i}
        <!-- {#each ['a', 'b'] as name, i} -->
        <div>
          <!-- {name}: {JSON.stringify(fields.value[i])} -->

          <Field name="{name}.name" let:field>
            <label for="{name}.name">Name</label>
            <input
              name={field.input.name}
              use:useHandlers={{ ...field.handlers, input: field.handlers.change, change: null }}
              type="text"
              value={field.input.value}
            />
          </Field>

          <button type="button" on:click={fields.remove(i)}>-</button>
        </div>
      {/each}
      <div class="button-group">
        <button type="button" on:click={() => fields.push({ name: '', email: '' })}>+</button>
      </div>

      <pre>meta: {JSON.stringify(meta, null, 2)}</pre>
    </FieldArray>

    <button type="submit" disabled={state.submitting}>Submit</button>
  </form>

  <pre>state.values: {JSON.stringify(state.values, null, 2)}</pre>
  <pre>state: {JSON.stringify(state, null, 2)}</pre>
</Form>

<script>
  import { Form, Field, FieldArray, useHandlers } from 'svelte-final-form'

  // import InputGroup from '@svelte/common/form/svelte-final-form/InputGroup.svelte'
  import arrayMutators from 'final-form-arrays'

  // import { UsesContext } from "svelte-final-form";
  // import UsesContext from '@svelte/common/form/svelte-final-form/UsesContext.svelte'

  const initialValues = {
    users: [{ name: 'user 1' }, { name: 'user 2' }],
  }

  const onSubmit = async (values) => {
    console.log(JSON.stringify(values, undefined, 2))
  }
</script>
