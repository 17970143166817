<p>
  <button use:useTippy={{ content: popover, trigger: 'click' }}> Show Popover </button>
  <Popover bind:root={popover}>
    <p>Here's the contents...</p>
    <p>...of this popover</p>
  </Popover>
</p>

<p>
  <Tippy trigger="click">
    <button slot="target" let:init use:init> Show Popover </button>
    <p>Here's the contents...</p>
    <p>...of this popover</p>
  </Tippy>
</p>

<p>
  <Tippy>
    <a slot="target" let:init use:init href="http://example.com/" on:click|preventDefault> Show Popover </a>
    <p>Here's the contents...</p>
    <p>...of this popover</p>
  </Tippy>
</p>

<!-- https://svelte.dev/repl/86d982b962ae4a1393e37630bd85a92b?version=3.31.2 -->
<script>
  import { useTippy } from '@svelte/common/tippy/useTippy.js'
  // import TooltipComponent from './TooltipContent.svelte'
  // import MyPopover from './MyPopover.svelte'
  import Popover from './Popover.svelte'
  // <link rel="stylesheet" src="https://unpkg.com/tippy.js@6/themes/light.css" />
  let popover

  import { Tippy } from '@svelte/common'
</script>
