import countries from 'i18n-iso-countries'
import countriesEn from 'i18n-iso-countries/langs/en.json'
countries.registerLocale(countriesEn)

export default countries

export function getCountryName(code, fallback = code) {
  let country = fallback
  if (code) {
    country = countries.getName(code, 'en')
  }
  return country
}
