import axios from 'axios'

// Without this, the default seems to be 'Accept: application/json, text/plain,
// */*'. The */* part confuses Rails and causes it to raise
// ActionController::UnknownFormat instead of recognizing it as a request for
// JSON.
axios.defaults.headers.get['Accept'] = axios.defaults.headers.post['Accept'] = 'application/json'

import '../common/http_interceptors/add_csrf_token'
import '../common/http_interceptors/pass_through_params'
