<p dir="auto">
  This is a bug reproduction for <a
    href="https://github.com/sveltejs/svelte/issues/8049"
    data-hovercard-type="issue"
    data-hovercard-url="/sveltejs/svelte/issues/8049/hovercard">Svelte issue #8049</a
  >.
</p>

<button
  on:click={() => {
    workarounds = !workarounds
  }}
>
  {workarounds ? 'Disable' : 'Enable'} workarounds
</button>

<ul class:workarounds>
  <li data-i="0">0. No problems when we use a div directly instead of Component</li>
  <Item data-i="1">1. Only a problem when using a Component instead of a div.</Item>
  <Item data-i="2">2. Workaround 1: don't use &amp;.</Item>
  <Item data-i="3">
    3. Workaround 2: Use :global {'{}'} from svelte-preprocess
  </Item>
</ul>

<script lang="ts">import Item from './Test_SassParentSelector/Item.svelte';
let workarounds = false;
</script>

<style lang="scss">ul {
  --green: #1bb133;
}

:global(li) {
  /*
  &:hover {
    background-color: yellow !important;
  }
  */
}
:global(li)[data-i="0"] {
  background-color: var(--green);
}
:global(li) :global(&[data-i="1"]) {
  background-color: var(--green);
}
:global(li)[data-i="2"] {
  background-color: var(--green);
}
:global(li)[data-i="3"] {
  background-color: var(--green);
}

:global(.workarounds li[data-i="1"]) {
  background-color: var(--green);
}

:global(.workarounds li[data-i="2"]) {
  background-color: var(--green);
}

:global(.workarounds) :global(li[data-i="3"]) {
  background-color: var(--green);
}</style>
