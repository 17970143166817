<Field {name} {type} {validate} {setIdToName} let:field>
  <InputGroup {field} {type} {...$$restProps} bind:element />
</Field>

<script lang="ts">import { Field } from 'svelte-final-form';
import { InputGroup } from '@svelte/common/form/svelte-final-form/';
export let name;
export let type = undefined;
export let validate = undefined;
export let setIdToName = true;
export let element = undefined;
</script>
