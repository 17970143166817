import '../common/axios_config'

// https://gist.github.com/OliverJAsh/acafba4f099f6e677dbb0a38c60dc33d
// Inspired by https://stackoverflow.com/a/37616104/5932012

declare global {
  interface ObjectConstructor {
    filter(obj: any, predicate: Function): any
  }
}

// export const filter = <K extends string, V, Result extends V>(
//     obj: Record<K, V>,
//     predicate: (key: K, value: V) => value is Result,
// ) => (
//     Object.fromEntries(
//         Object.entries(obj).filter(
//             (entry): entry is [K, Result] => {
//                 const [key, value] = entry;
//                 // return predicate(key, value);
//                 return predicate(key as any, value as any);
//             },
//         ),
//     )
// )

export const filter = <K extends string, V, Result extends V>(obj: Record<K, V>, predicate: (entry) => boolean) =>
  Object.fromEntries(Object.entries(obj).filter(predicate))

Object.filter = filter
