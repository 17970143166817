import { parsePhoneNumber } from 'react-phone-number-input/max'
import { defaultCountryCode, withoutMetadata } from '.'

// When the PhoneInput was rendered with no initial value, we set country to the
// current user's defaultCountry so that they can just enter a national number
// for their country, since that is more natural for them and therefore more
// user-friendly than forcing them to enter their country code.
//
// But if we are initializing the PhoneInput from (possibly invalid/incomplete) user input,
// we try to be smarter and use the country from the phone number, if possible.
// Test cases:
//   If an incomplete phone number like +61 436 221 (needs 3 more digits to be complete), set country to International instead of defaultCountry.
// Tested by: ___
export function assumedCountry(input) {
  // If we *can* determine the country from the phone number, then use that instead of the default country.
  const parsedIntlPhone = parsePhoneNumber(input || '')
  // console.log(`parsed ${input} as:`, withoutMetadata(parsedIntlPhone))
  if (parsedIntlPhone && parsedIntlPhone.country) {
    const parsedCountry = parsedIntlPhone.country
    // console.log('parsed country', parsedCountry, 'from', parsedIntlPhone)
    return parsedCountry
  } else if (parsedIntlPhone && parsedIntlPhone.countryCallingCode) {
    // If a country code is present, then this must be an international phone
    // number (probably with a +), but we apparently don't have a complete
    // enough number to be able to identify the country (some country calling
    // codes are shared by multiple countries), so let's make it clear that the
    // country is not yet known by leaving country blank (*not* setting to
    // defaultCountry).
    // This shouldn't even be necessary, should it? (According to https://github.com/catamphetamine/react-phone-number-input/issues/52)
    // I'm guess it only ignores the counry prop if the parsed phone value has a country?
    // console.log(`country could not be determined for ${input} and looks like international number so leaving country blank`)
    return null
  } else {
    return defaultCountryCode
  }
}
