// This includes all the misc. small, simple templates each of for which individually it would be
// overkill to have a dedicated pack file for.
import * as React from 'react'
import * as ReactDOM from 'react-dom'

import '../common/render_helpers'

import { UserWithAddress } from '@react/data_display/UserWithAddress'
import { NameAndDistance } from './NameAndDistance'

import { Address } from './Address'
import { ChurchInfo, ChurchNotFoundError } from '../church_transfer'

import { DetailsForFinder } from '../house_church_finder/DetailsForFinder'
import { ContactRequestOnFinder } from '../house_church_finder/ContactRequestOnFinder'

import { HouseChurchSearchResult } from '../search/HouseChurchSearchResult'
import { SimpleChurchSearchResult } from '../search/SimpleChurchSearchResult'
import { UserSearchResult } from '../search/UserSearchResult'
import { OrgSearchResult } from '../search/OrgSearchResult'

declare global {
  interface Window {
    PhoneNumber: any
    UserWithAddress: any
    NameAndDistance: any

    Address: any
    ChurchInfo: any
    ChurchNotFoundError: any

    DetailsForFinder: any
    ContactRequestOnFinder: any

    HouseChurchSearchResult: any
    SimpleChurchSearchResult: any
    UserSearchResult: any
    OrgSearchResult: any
  }
}

window.UserWithAddress = UserWithAddress
window.NameAndDistance = NameAndDistance

window.Address = Address
window.ChurchInfo = ChurchInfo
window.ChurchNotFoundError = ChurchNotFoundError

window.DetailsForFinder = DetailsForFinder
window.ContactRequestOnFinder = ContactRequestOnFinder

window.HouseChurchSearchResult = HouseChurchSearchResult
window.SimpleChurchSearchResult = SimpleChurchSearchResult
window.UserSearchResult = UserSearchResult
window.OrgSearchResult = OrgSearchResult
