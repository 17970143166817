<!-- svelte-ignore a11y-click-events-have-key-events -->

<!--
<Paper class="root">
<Content>
-->

<RailsForm action={url} on:submit={(event) => onSubmit(event, form)} bind:formEl>
  {#if utils.debug}
    <!-- svelte-ignore a11y-missing-attribute -->
    <p class="debug">
      <a
        role="button"
        tabindex="0"
        on:click={() => {
          debugFillIn(form, formEl)
        }}
      >
        Fill in this form for me (for testing)
      </a>
    </p>
  {/if}

  <p>Simple Church does not have a creed. The Bible is our creed and source of Truth.</p>
  <p>
    The summary listed below is only a summary. It is not comprehensive. It does seek to highlight present-day Truth
    that is relevant to present-day issues around the world.
  </p>

  <Paper class="list mdc-elevation--z5">
    <Content style="position: relative;">
      <div class="download_links top">
        <a download href="files/Bible Basics.pdf" title="Download this Summary as a PDF document">
          <ButtonIcon class="material-icons">get_app</ButtonIcon>Download
        </a>
        <a download href="files/Bible Basics.pdf" title="Download this Summary as a PDF document">
          <i class="far fa-file-pdf" aria-hidden="true" />
        </a>
        <a download href="files/Bible Basics.docx" title="Download this Summary as a Word (.docx) document">
          <i class="far fa-file-word" aria-hidden="true" />
        </a>
      </div>

      <ListItem name="checkbox_items[s1]" FormField$class="first">
        <span slot="title">Scripture</span>
        <p>
          Who or what decides what we ultimately believe? The Bible, God's Word, is the ultimate Truth Source. Truth
          claims are found in many areas, but the Bible teaches us the difference between real Truth and counterfeit
          truth. And it is the Bible that teaches us how to live here on earth while we wait for Jesus to come back and
          take us home to heaven. The Bible is our only creed. (
          <BibleRef ref="Isaiah 8:20" />;
          <BibleRef ref="Isaiah 40:8" />;
          <BibleRef ref="John 17:17" />;
          <BibleRef ref="2 Timothy 3:16-17" />;
          <BibleRef ref="2 Peter 1:20-21" />)
        </p>
      </ListItem>

      <ListItem name="checkbox_items[s2]">
        <span slot="title">Salvation</span>
        <p>
          What's so amazing about grace? First, God's grace saves us by providing forgiveness and the free gift of
          eternal life. That is why God sent His Son, Jesus, to this earth. Second, grace changes us. The Bible says
          that grace "teaches us to say 'No' to ungodliness and worldly passions and to live self-controlled, upright
          and godly lives" in this world (
          <BibleRef ref="Titus 2:11-12" />). Jesus came to show us how to turn away from sin and live a victorious life.
          We are both saved by grace and changed by grace. (
          <BibleRef ref="Ephesians 2:8-10" />;
          <BibleRef ref="John 3:16-17" />;
          <BibleRef ref="Acts 15:11" />;
          <BibleRef ref="Romans 5:1-2" />;
          <BibleRef ref="2 Corinthians 5:17-19" />;
          <BibleRef ref="1 John 5:11-13" />)
        </p>
      </ListItem>

      <ListItem name="checkbox_items[s3]">
        <span slot="title">Spirit-filled</span>
        <p>
          People often wonder, how can I be filled with the Holy Spirit? Scripture is clear that God is more than
          willing to give us the Holy Spirit when we ask and obey (
          <BibleRef ref="Luke 11:11-13" />;
          <BibleRef ref="Acts 5:32" />).
        </p>
        <div class="indent">
          <p>
            <b>Fruits of the Spirit</b>
            – The “Spirit of truth” (
            <BibleRef ref="John 16:13" />) gives to all Truth-followers the fruits of the Spirit—"love, joy, peace,
            forbearance, kindness, goodness, faithfulness, gentleness, and self-control. Against such things, there is
            no law. Those who belong to Christ Jesus have crucified the flesh with its passions and desires. Since we
            live by the Spirit, let us keep in step with the Spirit" (
            <BibleRef ref="Galatians 5:22-23" />). These fruits are for all of God's people.
          </p>
          <p>
            <b>Gifts of the Spirit</b>
            – In addition to the fruits of the Spirit, the Holy Spirit also distributes the gifts of the Spirit as He chooses.
            The "gift list" is found in several places in the Bible. These special gifts help you do the work God invites
            you to do when you accept His invitation to join Him in missionary work. (
            <BibleRef ref="1 Corinthians 12:1-31" />;
            <BibleRef ref="Romans 12:3-8" />;
            <BibleRef ref="Ephesians 4:1-16" />;
            <BibleRef ref="1 Peter 4:10-11" />). Even though each person is given some gift(s), there is no single gift
            given to all (
            <BibleRef ref="1 Corinthians 12:29-31" />). The gifts of the Spirit function in a complementary way, like
            the various parts of the body, “for the common good,” to edify God’s people (
            <BibleRef ref="1 Corinthians 14:12, 26" />;
            <BibleRef ref="Ephesians 4:12" />).
          </p>
        </div>
      </ListItem>

      <ListItem name="checkbox_items[s4]">
        <span slot="title">Sanctuary</span>
        <p>
          Have you ever heard of a bird sanctuary or an elephant sanctuary? A sanctuary is a safe place. Have you ever
          wondered what Jesus is now doing in heaven? The Bible teaches us that Jesus is in the heavenly sanctuary, a
          heavenly “safe place.” He is investigating the lives of all who have ever lived and all who are now living to
          see if they have been saved and changed by God's grace. For Jesus' followers, this sanctuary is a safe place
          because Jesus is both our judge and our defense lawyer. In other words, if you are obeying the Holy Spirit as
          written in the Bible, then you are saved and being changed by grace. That means you are safe with God. Now
          that is good news! (
          <BibleRef ref="Daniel 7:9-10, 22" />;
          <BibleRef ref="Hebrews 8:1-6; 9:11-28" />;
          <BibleRef ref="Revelation 22:12" />)
        </p>
        <p>
          The heavenly sanctuary is also the center of operations for the plan of redemption. God directed Moses to make
          an earthly replica of the heavenly sanctuary to illustrate the story of our salvation. It showed both God’s
          perfect justice and His perfect mercy. God’s covenant law, the Ten Commandments, was placed inside the ark of
          the covenant in the heart of the sanctuary. God’s values and His character were represented in His law; He
          could not justly change it, deny it, or annul it to accommodate man’s sin. When people sinned, God had to
          either inflict on them the penalty of the law—or pay it Himself. In mercy, He chose to pay the death penalty.
          His payment was poignantly illustrated in the sanctuary by the sacrifice of the perfect, innocent lamb (
          <BibleRef ref="Hebrews 8:1-6; 9:1-15, 23-28" />;
          <BibleRef ref="Psalm 85:10" />;
          <BibleRef ref="Deuteronomy 10:1-5" />;
          <BibleRef ref="Psalm 119:142" />;
          <BibleRef ref="Matthew 5:17-18" />;
          <BibleRef ref="2 Timothy 2:13" />;
          <BibleRef ref="Romans 3:21-26" />;
          <BibleRef ref="John 1:29" />;
          <BibleRef ref="1 Peter 1:17-19" />).
        </p>
        <p>
          Christians reflect their understanding of God’s plan for their salvation when they uphold His law by 1)
          keeping the Ten Commandments and 2) in faith depending upon Jesus’ sacrifice for their right standing with God
          (
          <BibleRef ref="Revelation 14:12" />).
        </p>
      </ListItem>

      <ListItem name="checkbox_items[s5]">
        <span slot="title">Sabbath</span>
        <p>
          Sabbath worship is as old as Creation. It was part of the template for a perfect world. Before Adam and Eve
          sinned, this creation template included (besides the institution of marriage between a man and a woman) the
          seventh-day Sabbath as a blessed and sanctified day for rest, restoration, and relationships, a sacred space
          in time—24 hours—which begins at sunset on Friday evening. And why does it begin Friday evening? "The evening
          and the morning" mark a biblical day (
          <BibleRef ref="Genesis 1:5, 8, 13" />, etc.; see also
          <BibleRef ref="Luke 23:54-24:1" />).  And "God blessed the seventh day and made it holy" (
          <BibleRef ref="Genesis 2:3" />). So, what are the blessings we receive when we do it God’s way on God’s day?
        </p>
        <p>The Bible tells us:</p>
        <ul>
          <li>
            Sabbath is a day to
            <b>PREPARE</b>
            for (
            <BibleRef ref="Luke 23:50-56" />).
          </li>
          <li>
            Sabbath is a day to
            <b>REST</b>
            from our work, just like God did when He created the world (
            <BibleRef ref="Hebrews 4:1-13" />).
          </li>
          <li>
            Sabbath is a day to
            <b>AVOID</b> focusing on my stuff (

            <BibleRef ref="Isaiah 58:13-14" />).
          </li>
          <li>
            Sabbath is a day to
            <b>REMEMBER</b>
            God and do something
            <b>DIFFERENT</b>
            than your occupation of the rest of the week (
            <BibleRef ref="Exodus 20:8-11" />).
          </li>
          <li>
            Sabbath is a day to
            <b>ATTEND TO HUMAN NEED</b>
            (
            <BibleRef ref="Mark 2:23-28" />).
          </li>
          <li>
            Sabbath is a day for
            <b>HEALING</b>
            and
            <b>PRESERVING LIFE</b>
            (
            <BibleRef ref="Matthew 12:9-14" />;
            <BibleRef ref="Luke 6:6-11; 13:10-17; 14:1-6" />).
          </li>
          <li>
            Sabbath is a day to
            <b>GATHER</b>
            with Truth-seekers (
            <BibleRef ref="Luke 4:16" />; 
            <BibleRef ref="Acts 16:13" />).
          </li>
          <li>
            Sabbath is, above all, a day for
            <b>RELATIONSHIP</b>
            with God and with people, for it was given in a perfect world, before man needed relief from weariness or secular
            pursuits (
            <BibleRef ref="Genesis 2:1-3" />).
          </li>
        </ul>
      </ListItem>

      <ListItem name="checkbox_items[s6]">
        <span slot="title">Sleep of Death</span>
        <p>
          When people die, do they go "up" or "down"? Neither. They go "to sleep." The Scripture says, "for in the
          grave, where you are going, there is neither working nor planning nor knowledge nor wisdom" (
          <BibleRef ref="Ecclesiastes 9:10" />). But when Jesus comes back, His faithful followers will be resurrected
          from the grave. The Bible says that those who "sleep in the dust of the earth will awake" (
          <BibleRef ref="Daniel 12:2" />). At that time, we will be given immortality and will live forever with God. (
          <BibleRef ref="Genesis 2:7" />;
          <BibleRef ref="Psalm 104:29" />;
          <BibleRef ref="John 11:11-44" />;
          <BibleRef ref="Ecclesiastes 12:7" />;
          <BibleRef ref="1 Timothy 6:16" />;
          <BibleRef ref="1 Corinthians 15:51-58" />).
        </p>
      </ListItem>

      <ListItem name="checkbox_items[s7]">
        <span slot="title">Stewardship</span>
        <p>
          What comes to mind when you hear the word stewardship? For many people, it is money. But this definition is
          too narrow because stewardship includes all of life. Bible stewardship says that everything we have belongs to
          God. It is on loan for us to take care of. How would you answer these stewardship questions?
        </p>
        <ul>
          <li>
            How are you placing God’s interests first every day? (
            <BibleRef ref="Matthew 6:33; 25:14-30" />)
          </li>
          <li>
            How are you choosing the best for your health (rest, diet, exercise, etc.)? (
            <BibleRef ref="Romans 12:1" />;
            <BibleRef ref="1 Corinthians 6:19-20; 10:31" />)
          </li>
          <li>
            How are you giving primary relationships, like family, your top priority, to the honor of God? (
            <BibleRef ref="Ecclesiastes 9:9" />;
            <BibleRef ref="Titus 2:4" />;
            <BibleRef ref="1 Timothy 5:8" />)
          </li>
          <li>
            How are you becoming financially healthy? (
            <BibleRef ref="Deuteronomy 15:6-8" />;
            <BibleRef ref="Psalm 112:5" />;
            <BibleRef ref="Proverbs 6:1-5; 22:7, 26-27; 23:4-5; 28:27" />;
            <BibleRef ref="Ecclesiastes 5:13-14" />)
          </li>
          <li>
            How are you faithfully giving tithe and offerings to advance Kingdom work? (
            <BibleRef ref="Malachi 3:8-10" />;
            <BibleRef ref="2 Chronicles 31:4-6" />;
            <BibleRef ref="Luke 6:38" />;
            <BibleRef ref="2 Corinthians 9:6-8" />)
          </li>
        </ul>
      </ListItem>

      <ListItem name="checkbox_items[s8]">
        <span slot="title">Second Coming</span>
        <p>
          When will this world end? It will end when Jesus comes back the very next time. Nobody knows exactly when this
          will happen, but the Bible says we can know when it is near (
          <BibleRef ref="Mark 13:28-29" />;
          <BibleRef ref="Luke 21:29-31" />), and Bible prophecy indicates that it will be soon (
          <BibleRef ref="Daniel 2:1-45" />;
          <BibleRef ref="Matthew 24:1-51" />;
          <BibleRef ref="Mark 13:1-37" />;
          <BibleRef ref="Luke 21:6-36" />). Jesus promised, "When everything is ready, I will come and get you, so that
          you will always be with me where I am" (
          <BibleRef ref="John 14:3" />).
        </p>
      </ListItem>
    </Content>
  </Paper>
  <!-- <div class="list"> -->

  <p>
    To emphasize again, this summary is only a summary. It is not comprehensive. It does seek to highlight present-day
    Truth that is relevant to present-day issues around the world.
  </p>
  <p>
    As part of the Simple Church Global Network, I embrace this summary of God’s Written Word and seek to obey it and
    share it with other Truth-seekers.
  </p>

  {#if read_only}
    <!-- No button needed -->
  {:else}
    <ContinueButton>I Agree</ContinueButton>
  {/if}
</RailsForm>

<!--
</Content>
</Paper>
-->

{#if utils.debug}
  <pre class="debug">state.values: {JSON.stringify(state.values, null, 2)}</pre>
  <pre class="debug">state: {JSON.stringify(state, null, 2)}</pre>
{/if}

<script lang="ts">import { onMount } from 'svelte';
import 'svelte-elements';
// final-form
import { createForm } from 'svelte-final-form';
import create_focusOnError from 'final-form-focus';
import { changeValues } from 'common/final-form';
// svelte-material-ui
import { Icon as ButtonIcon } from '@smui/button';
import Paper, { Content } from '@smui/paper';
import { utils } from '../../../common/utils';
import { RailsForm } from '@svelte/common/form';
import ContinueButton from '../ContinueButton.svelte';
import ListItem from './ListItem.svelte';
import BibleRef from '../../common/BibleRef.svelte';
export let step_completed = false;
export let read_only = false;
export let url;
let formEl = undefined;
// { final-form
const onSubmit = (event, form) => {
    // I wish this had a useful (valid/invalid) return value that we could check.
    form.submit();
    if (form.getState().submitFailed) {
        event.preventDefault();
    }
    else {
        // Allow form to submit normally to server
    }
};
const onValidSubmit = (values) => {
    // console.log('valid', JSON.stringify(values, undefined, 2))
    // console.log('valid', form.getState())
};
let initialValues = {};
const filledInValues = {
    checkbox_items: {
        s1: true,
        s2: true,
        s3: true,
        s4: true,
        s5: true,
        s6: true,
        s7: true,
        s8: true,
    },
};
if (step_completed) {
    initialValues = Object.assign(Object.assign({}, initialValues), filledInValues);
}
const [form, stateStore] = createForm({
    onSubmit: onValidSubmit,
    decorators: [create_focusOnError()],
    initialValues: initialValues,
    mutators: {
        changeValues,
    },
});
$: state = $stateStore;
export const debugFillIn = (form, rootElement) => {
    form.mutators.changeValues(filledInValues);
    const formValues = form.getState().values;
    console.log(formValues);
    console.log(rootElement);
    const submit = rootElement.querySelector('button[type=submit]');
    // const firstInput = rootElement.querySelector('input')
    submit.focus();
};
// } final-form
onMount(() => {
    if (formEl) {
        // console.log('checkbox', formEl.querySelector('input[type="checkbox"]'))
        formEl.querySelector('input[type="checkbox"]').focus();
    }
});
</script>

<style lang="scss">:global(.smui-paper) {
  padding: 24px 24px;
}

:global(.material-icons) {
  vertical-align: middle;
}

:global(.mdc-button) {
  text-transform: none;
}

:global(.list) {
  margin: 2.5em 2.5em 2.5em 2.5em;
  padding: 2em 4em 2em 2em;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgba(245, 245, 220, 0.2745098039);
  /* apply above to all except first one */
}
:global(.list) .download_links {
  position: absolute;
  font-size: 16px;
}
:global(.list) .download_links a[download]:nth-of-type(2) {
  margin-left: 0.3em;
}
:global(.list) .download_links a[download]:nth-of-type(3) {
  margin-left: 0.1em;
}
:global(.list) .download_links :global(.material-icons) {
  margin-bottom: 3px;
  margin-right: 0.2em;
}
:global(.list) .download_links .far {
  font-size: 19px;
  vertical-align: baseline;
}
:global(.list) .download_links.top {
  top: -20px;
  right: -45px;
}
:global(.list) :global(.mdc-form-field) {
  margin-top: 1em;
}
:global(.list) :global(.mdc-form-field.first) {
  margin-top: 0;
}</style>
