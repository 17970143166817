import * as React from 'react'
import { RefObject } from 'react'

export default function withFocusMethod(Wrapped) {
  return class extends React.Component {
    input: RefObject<HTMLInputElement>
    constructor(props) {
      super(props)
      this.input = React.createRef()
    }
    focus = () => {
      // console.log(this.input.current)
      this.input.current.focus()
    }
    render() {
      return <Wrapped {...this.props} inputRef={this.input} />
    }
  }
}
