<!-- <div>{fieldName}: {JSON.stringify(errors, null, 2)}</div> -->
<!-- {Object.entries(errors).map(([field, errorsForField], i) => ( -->
<!-- {#each Object.entries(errors) as (error} -->
<!-- {#each errors as error} -->
{#if typeof errors == 'string'}
  <li>
    {#if fieldName}{fieldName}:{/if}
    {errors}
  </li>
{/if}

<!-- {/each} -->
<script lang="ts">import '@smui/paper';
export let fieldName;
export let errors;
</script>
