<!-- <pre>errors: {JSON.stringify(errors, null, 2)}</pre> -->
{#if errors.length > 0}
  <Paper class="root">
    <Title>The following errors prevented this form from saving:</Title>
    <Content>
      <ul>
        {#each errors as [field, errorsForField], i}
          <ErrorsForField fieldName={field} errors={errorsForField} />
        {/each}
      </ul>
    </Content>
  </Paper>
{/if}

<script lang="ts">import Paper, { Title, Content } from '@smui/paper';
import { useFormState } from 'svelte-final-form';
import { ErrorsForField } from '.';
import { flattenErrors } from 'common/flattenErrors';
// let formState = useFormState()
let formState = useFormState({ subscription: { submitErrors: true, visited: true } });
let submitErrors, knownFields;
// $: console.log('Submission:', $formState)
$: ({ submitErrors, visited: knownFields } = $formState);
// $: console.log('submitErrors:', submitErrors)
// $: console.log('knownFields:', knownFields)
// Flatten from {house_church_registration:{given_name:"can't be blank"}} to {"house_church_registration.given_name":"can't be blank"}
let flattened;
$: {
    if (submitErrors) {
        flattened = {};
        Object.entries(submitErrors).map(([field, errorsForField]) => {
            if (typeof errorsForField == 'string' || Array.isArray(errorsForField)) {
                flattened = Object.assign({ [field]: errorsForField }, flattened);
            }
            else {
                // console.log(field, 'is object:', errorsForField)
                // console.log('flattening', field, ':', flattenErrors(field, errorsForField))
                flattened = Object.assign(Object.assign({}, flattenErrors(field, errorsForField)), flattened);
                // If it is an "object", then it is a nested object and can be safely ignored. We add the same error using nested and flat key so here we're just looking for flat keys with string values.
            }
        });
        console.log('flattened:', flattened);
    }
}
let errors = [];
// Filter out any "known" errors that would already be shown next to the fields above.
// But then the message "The following errors prevented this form from saving:" doesn't make sense, so I removed this.
// $: {
//   if (flattened) {
//     errors = Object.filter(flattened, ([field, errorsForField]) => {
//       console.log('checking', field, 'in:', knownFields)
//       if (field in knownFields) {
//         // The error will show up by the field itself in the form (using field.meta.submitError) so no need to list in top-level errors list
//         // console.log(`Filtering out ${field} from top-level error list because it is in the form`)
//         return false
//       }
//       return true
//     })
//   }
// }
$: {
    if (flattened) {
        const filtered = Object.filter(flattened, ([field, errorsForField]) => {
            // These refer to the same field, so remove the duplicate.
            // user.given_name: is required
            // house_church_registration.given_name
            if (field === 'user.given_name') {
                return false;
            }
            return true;
        });
        errors = Object.entries(filtered).map(([field, errorsForField]) => {
            return [
                // Humanize the field name
                ...[field].map((field) => field.replace(/(\w*\.)*/, '')),
                errorsForField,
            ];
        });
    }
}
</script>

<style>
  :global(.root) {
    background-color: rgba(255, 0, 0, 0.05);
    color: #c00;
    padding: 3rem 2rem;
  }
</style>
