import * as React from 'react'
import * as ReactDOM from 'react-dom'

declare global {
  interface Window {
    React: any
    ReactDOM: any
    render_template: any
    react_render: any
  }
}

window.React = React
window.ReactDOM = ReactDOM

// Returns DOM element containing rendered content
// Similar to ReactDOMServer.renderToString() but returns actual element instead of string.
window.render_template = function (component, props) {
  const container = document.createElement('div')
  window.react_render(component, props, container)
  return container
}

// More concise wrapper for ReactDOM.render and React.createElement
window.react_render = function (component, props, container) {
  ReactDOM.render(
    //<Hello compiler="TypeScript" framework="React" />,
    React.createElement(component, props),
    container
  )
}
