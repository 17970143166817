import * as React from 'react'

declare global {
  interface Window {
    house_icon_path: any
  }
}

export interface UserWithAddressProps {
  title: string
  address?: any
}

export function UserWithAddress(props: UserWithAddressProps) {
  return (
    <div className="user search_result clear_left">
      <div className="float_right"></div>

      <div className="icon">
        <img alt="User" src={window.house_icon_path} width="60" height="53" />
      </div>
      <div className="title float_left">{props.title}</div>

      {typeof props.address == 'object' && <div className="address float_left">{props.address.address}</div>}
      {typeof props.address == 'object' && (
        <div className="city_state_country float_left">{props.address.city_state_country}</div>
      )}

      <br className="clear" />
    </div>
  )
}
