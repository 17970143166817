<section>
  <h2>Menu Surface</h2>

  <div>
    <MenuSurface static style="max-width: 350px;">
      <p style="margin: 1em;">
        This is a menu surface. It's similar to a menu. It is more versatile, but requires more configuration. It can
        contain more than just a list, like rich popover content, forms, images, etc.
      </p>
    </MenuSurface>
  </div>

  <div>
    <MenuSurface static>
      <List>
        <Item on:SMUI:action={() => (clicked = 'Cut')}>
          <Text>Cut</Text>
        </Item>
        <Item on:SMUI:action={() => (clicked = 'Copy')}>
          <Text>Copy</Text>
        </Item>
        <Item on:SMUI:action={() => (clicked = 'Paste')}>
          <Text>Paste</Text>
        </Item>
        <Separator />
        <Item on:SMUI:action={() => (clicked = 'Delete')}>
          <Text>Delete</Text>
        </Item>
      </List>
    </MenuSurface>
  </div>

  <pre class="status">Clicked: {clicked}</pre>

  <div>
    Anchored automatically, corner set to bottom-left:

    <div style="min-width: 100px;">
      <Button on:click={() => formSurface.setOpen(true)}>Open Menu Surface</Button>
      <MenuSurface bind:this={formSurface} anchorCorner="BOTTOM_LEFT">
        <div style="margin: 1em; display: flex; flex-direction: column; align-items: flex-end;">
          <Textfield bind:value={name} label="Name" />
          <Textfield bind:value={email} label="Email" type="email" />
          <Button style="margin-top: 1em;" on:click={() => formSurface.setOpen(false)}>Submit</Button>
        </div>
      </MenuSurface>
    </div>
  </div>

  <div>
    Anchored manually:

    <div
      class={imageListAnchorClasses.join(' ')}
      use:Anchor={{ classForward: (classes) => (imageListAnchorClasses = classes) }}
      bind:this={imageListAnchor}
    >
      <Button on:click={() => imageListSurface.setOpen(true)}>Open Menu Surface</Button>
      <MenuSurface bind:this={imageListSurface} anchor={false} bind:anchorElement={imageListAnchor}>
        <ImageList class="menu-surface-image-list">
          {#each Array(4) as _unused, i}
            <ImageListItem>
              <ImageAspectContainer>
                <Image src="https://via.placeholder.com/100x100.png?text=Image%20{i + 1}" alt="Image {i + 1}" />
              </ImageAspectContainer>
            </ImageListItem>
          {/each}
        </ImageList>
      </MenuSurface>
    </div>
  </div>

  <div>
    Anchored manually to inline links?:

    <div>
      <b>Gifts of the Spirit</b>
      – In addition to the fruits of the Spirit, the Holy Spirit also distributes the gifts of the Spirit as He chooses.
      The "gift list" is found in several places in the Bible. These special gifts help you do the work God invites you to
      do when you accept His invitation to join Him in missionary work. (
      <a
        on:click={(event) => {
          event.preventDefault()
          imageListSurface2.setOpen(true)
        }}
        href="https://www.biblegateway.com/passage/?search=1 Corinthians 12:1-31&amp;version=NKJV&amp;src=tools"
        target="_blank"
        class="bible_reference svelte-r4798"
        >1 Corinthians 12:1-31
        <MenuSurface bind:this={imageListSurface2} anchorCorner="BOTTOM_LEFT">
          <p style="min-width: 300px">Awesome stuff</p>
          <p>Awesome stuff</p>
          <p>Awesome stuff</p>
          <p>Awesome stuff</p>
          <p>Awesome stuff</p>
        </MenuSurface>
      </a>
      <b>Gifts of the Spirit</b>
      – In addition to the fruits of the Spirit, the Holy Spirit also distributes the gifts of the Spirit as He chooses.
      The "gift list" is found in several places in the Bible. These special gifts help you do the work God invites you to
      do when you accept His invitation to join Him in missionary work. (<a
        href="https://www.biblegateway.com/passage/?search=1 Corinthians 12:1-31&amp;version=NKJV&amp;src=tools"
        target="_blank"
        class="bible_reference svelte-r4798">1 Corinthians 12:1-31</a
      >;
      <a
        href="https://www.biblegateway.com/passage/?search=Ephesians 4:1-16&amp;version=NKJV&amp;src=tools"
        target="_blank"
        class="bible_reference svelte-r4798">Ephesians 4:1-16</a
      >
      ;
      <a
        href="https://www.biblegateway.com/passage/?search=1 Peter 4:10-11&amp;version=NKJV&amp;src=tools"
        target="_blank"
        class="bible_reference svelte-r4798">1 Peter 4:10-11</a
      >
      ). Even though each person is given some gift(s), there is no single gift given to all (<a
        href="https://www.biblegateway.com/passage/?search=1 Corinthians 12:29-31&amp;version=NKJV&amp;src=tools"
        target="_blank"
        class="bible_reference svelte-r4798">1 Corinthians 12:29-31</a
      >
      ). The gifts of the Spirit function in a complementary way, like the various parts of the body, “for the common good,”
      to edify God’s people (<a
        href="https://www.biblegateway.com/passage/?search=1 Corinthians 14:12, 26&amp;version=NKJV&amp;src=tools"
        target="_blank"
        class="bible_reference svelte-r4798">1 Corinthians 14:12, 26</a
      >
      ).
    </div>
  </div>

  <div style="padding-top: 200px;">Long div for scrolling...</div>
</section>

<script>
  import MenuSurface, { Anchor } from '@smui/menu-surface'
  import List, { Item, Separator, Text } from '@smui/list'
  import Textfield from '@smui/textfield'
  import ImageList, { Item as ImageListItem, ImageAspectContainer, Image } from '@smui/image-list'
  import Button from '@smui/button'
  import './menu-surface.scss'
  let formSurface
  let name = ''
  let email = ''

  let imageListSurface
  let imageListAnchor
  let imageListAnchorClasses = []

  let imageListSurface2
  let imageListAnchor2
  let imageListAnchorClasses2 = []

  let clicked = 'nothing yet'
</script>
