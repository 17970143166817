// Based on react-phone-number-input/source/isValidPhoneNumber.js
// and react-phone-number-input/max/index.js

import metadata from 'libphonenumber-js/metadata.full.json'
import { parsePhoneNumberFromString } from 'libphonenumber-js/core'

function call(func, _arguments) {
  var args = Array.prototype.slice.call(_arguments)
  args.push(metadata)
  return func.apply(this, args)
}

function _isPossiblePhoneNumber(value, metadata) {
  if (!value) {
    return false
  }
  const parsedPhone = parsePhoneNumberFromString(value, metadata)
  if (!parsedPhone) {
    return false
  }
  // TODO: Only return true if type is fixed or mobile or toll-free/etc (like we do in Ruby). But it doesn't look like type is available unless number is *valid* (not just possible). There is no possible_types available like in the Ruby library.
  // console.log('parsedPhone.getType()', parsedPhone.getType())
  return parsedPhone.isPossible()
}

export default function isPossiblePhoneNumber(value) {
  return call(_isPossiblePhoneNumber, arguments)
}
