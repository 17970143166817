{#if show}
  <div use:setPopperContent={popperOptions}>
    <div id="arrow" data-popper-arrow />
    <Paper>
      <Content>
        <slot />
      </Content>
    </Paper>
  </div>
{/if}

<script>
  import Paper, { Content } from '@smui/paper'
  import { createPopperActions } from 'svelte-popperjs'

  export let show = false
  export let popperReference
  export let popperOptions = {
    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
  }

  const [setPopperRef, setPopperContent] = createPopperActions()
  $: popperReference && setPopperRef(popperReference)
</script>

<style>
  #arrow,
  #arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: -1;
  }

  #arrow::before {
    content: '';
    transform: rotate(45deg);
    background: #ddd;
  }

  :global(#tooltip[data-popper-placement^='top']) > #arrow {
    bottom: -4px;
  }

  :global(#tooltip[data-popper-placement^='bottom']) > #arrow {
    top: -4px;
  }

  :global(#tooltip[data-popper-placement^='left']) > #arrow {
    right: -4px;
  }

  :global(#tooltip[data-popper-placement^='right']) > #arrow {
    left: -4px;
  }
</style>
