<!-- <pre>{input.name}: {error}; {JSON.stringify(meta, null, 2)}</pre> -->
{#if error}
  <div class="errors error_message_on" data-field-name={input.name}>
    <div {...$$restProps}>{error}</div>
  </div>
{/if}

<script lang="ts">export let field;
export let when;
when = when || 'touched';
let whenFunc;
$: if (when === 'submitFailed') {
    whenFunc = (meta) => meta.submitFailed;
}
else if (typeof when === 'function') {
    whenFunc = when;
}
else {
    whenFunc = (meta) => !!meta.touched;
}
let input, meta, handlers;
$: ({ input, meta, handlers } = field);
$: error = whenFunc(meta) && meta.error ? meta.error : meta.submitError;
</script>
