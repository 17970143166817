import axios from 'axios'

// https://www.npmjs.com/package/axios#interceptors
axios.interceptors.request.use(
  (request) => {
    const el = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement
    // It won't be present in test env, apparently
    if (el) {
      const csrf_token = el.content
      request.headers['X-CSRF-Token'] = csrf_token
    }
    return request
  },
  (err) => {
    return Promise.reject(err)
  }
)
