import * as React from 'react'

export interface NameAndDistanceProps {
  name: string
  formatted_distance: string
}

export function NameAndDistance(props: NameAndDistanceProps) {
  return (
    <div className="name_and_distance search_result clear_left">
      <div className="float_left">{props.name}</div>
      <div className="float_right">{props.formatted_distance}</div>
      <br className="clear" />
    </div>
  )
}
