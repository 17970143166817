import * as React from 'react'

export interface ChurchNotFoundErrorProps {
  name: string
  churches_count: number
  conference_name: string
}

export function ChurchNotFoundError(props: ChurchNotFoundErrorProps) {
  return (
    <div>
      <span className="blue_bold">No churches were found with the name "{props.name}". </span>
      Please check your spelling and try again. Is there any other name by which your church may be listed? If your
      church isn't in our database, please check “My church wasn't listed or could not be found” (below). (Our database
      contains {props.churches_count} churches for the
      {props.conference_name}.)
    </div>
  )
}
