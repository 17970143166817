<form>
  <fieldset>
    <legend>Primary language</legend>
    <LanguageSelect name="primary_language" bind:value />

    <div>selected value: {JSON.stringify(value)}</div>
  </fieldset>

  <fieldset>
    <legend>Secondary languages</legend>
    <p>
      Are there any other languages you read/write fluently?
      <LanguageSelect name="languages" bind:value={secondary_languages} multiple />
    </p>
    <div>selected secondary_languages: {JSON.stringify(secondary_languages)}</div>
  </fieldset>

  <Button label_text="Submit" />
</form>

<div>language from browser: {JSON.stringify(getLanguageFromBrowser())}</div>

<script>
  import LanguageSelect from '@svelte/common/form/fields/LanguageSelect.svelte'
  // import { getLocaleFromNavigator } from 'svelte-i18n'
  import { getLanguageFromBrowser } from '@svelte/common/languages'
  import Button from '@svelte/common/Button.svelte'

  let value
  let secondary_languages = []
</script>
