//══════════════════════════════════════════════════════════════════════════════════════════════════
// Utils

export const defaultCountryCode = document.querySelector('html').dataset.defaultCountryCode

export function withoutMetadata(_parsedPhone) {
  if (!_parsedPhone) return _parsedPhone
  const { metadata: _metadata, ...parsedPhone } = _parsedPhone
  return parsedPhone
}
