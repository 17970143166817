<div bind:this={container} />

<script>
  // Fork of https://github.com/jesseskinner/svelte-react/blob/master/ReactComponent.svelte
  import * as React from 'react'
  import * as ReactDOM from 'react-dom'
  import { onMount, afterUpdate, onDestroy } from 'svelte'

  let component
  let className
  export let children = undefined
  export { component as this }
  export { className as class }
  $: props = {
    className,
    ...$$restProps,
  }

  let container

  onMount(() => {
    // console.log(`${component.displayName}: mounted, with:`, props)
  })
  afterUpdate(() => {
    // console.log(`${component.displayName}: (re-)rendered with:`, props)
    ReactDOM.render(React.createElement(component, props, children), container)
  })
  onDestroy(() => {
    ReactDOM.unmountComponentAtNode(container)
  })
</script>
