import * as React from 'react'

declare global {
  interface Window {
    union_icon_path: any
  }
}

export interface OrgSearchResultProps {
  name: string
  class_name?: string
  phone?: string
  email?: string
  human_state?: string

  address?: any
  rep_user?: any
}

export function OrgSearchResult(props: OrgSearchResultProps) {
  return (
    <div className="org_entity search_result clear_left">
      <div className="float_right">
        {props.class_name && <div className="class_name">{props.class_name}</div>}
        {props.phone && <div className="phone">{props.phone}</div>}
        {props.email && <div className="email">{props.email}</div>}
      </div>

      <div className="icon">
        <img alt="Org" src={window.union_icon_path} />
      </div>

      <div className="title float_left">
        {props.name}
        {props.human_state && (
          <span>
            —<span className="{props.state} org_entity_state">{props.human_state}</span>
          </span>
        )}
      </div>

      {typeof props.address == 'object' && (
        <div className="city_state_country float_left">{props.address.city_state_country}</div>
      )}

      {typeof props.rep_user == 'object' && (
        <div className="rep_user float_left">
          {props.rep_user.name},{props.rep_user.title}
        </div>
      )}

      <br className="clear" />
    </div>
  )
}
