import { deleteAllProps, skipValidations, isObject } from '../../common/utils'

//══════════════════════════════════════════════════════════════════════════════════════════════════
// Validators
// (some is duplicated with app/javascript/@react/forms/index.tsx)

// This is generally not required if we use the Field from 'react-final-form-html5-validation' but is needed if we use Field from 'react-final-form'
// export const required = (value) => (value ? undefined : 'is required')
export const requiredWithMessage = (message) => (value) =>
  value && (!Array.isArray(value) || value.length) ? undefined : message
export const required = requiredWithMessage('is required')

export const checkValidEmail = (value) => {
  if (!value) {
    return
  }
  if (!/@.+\..+/.test(value)) {
    return 'is not a valid e-mail address'
  }
}

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined)

//──────────────────────────────────────────────────────────────────────────────────────────────────

export const ifValidationsEnabled = (validator) => (value) => {
  // console.log('skip?', skipValidations(), window.skip)
  if (skipValidations()) {
    // console.log('Skipping validations')
    return undefined
  }
  return validator(value)
}

//══════════════════════════════════════════════════════════════════════════════════════════════════
// Mutators

export function changeValues([values], { formState }, { changeValue }) {
  // Like initialize but changes values rather than initialValues
  Object.entries(values).forEach(([name, value]) => {
    changeValue({ formState }, name, (_value) => value)
  })
}

// Based on: https://codesandbox.io/embed/revalidate-successfully-on-submit-react-final-form-xtzmf
export function revalidate([name, n], { formState }, { changeValue }) {
  // Change the value to the same value, thus triggering a revalidation of the same value
  changeValue({ formState }, name, (value) => n)
}

// Based on: https://github.com/final-form/final-form-set-field-touched/blob/master/src/setFieldTouched.js
// Based on: changeValue from final-form/src/FinalForm.js
// Although this successfully clears the errors for a moment, unless you pause validation, after running this (or any) mutator, it will immediately run validations again and add the error back that you just cleared!
// Actually, now it doesn't even seem to clear errors (for very long) when I try to pause validation from a submit handler.
export function clearError([name], { formState }, { setIn }) {
  if (name) {
    formState.errors = setIn(formState.errors, name, undefined) || {}
  } else {
    deleteAllProps(formState.errors)
  }
}

// Input is an object, which can be either of:
// - field_name : object  (nested)
//   "registration": {
//     "registrant_attributes": {
//       "email": "is required",
//     },
//   }
// - field.name : string  (flat)
//   'house_church_registration.registrant_attributes.email': 'is required',
export function setSubmitErrors([submitErrors], { formState }, { setIn }) {
  formState.submitErrors = submitErrors // this is needed/used for the case of nested object values
  Object.entries(submitErrors).forEach(([name, value]) => {
    if (!isObject(value)) {
      // if it's flat, we assume key is like "field.name" and requires use of setIn
      formState.submitErrors = setIn(formState.submitErrors, name, value) || {}
    }
  })
}

//══════════════════════════════════════════════════════════════════════════════════════════════════
// Misc.

export const simpleMemoize = (fn) => {
  let lastArg
  let lastResult
  return (arg) => {
    if (arg !== lastArg) {
      lastArg = arg
      lastResult = fn(arg)
    }
    return lastResult
  }
}
