<p>Compare to <a href="/test_svelte/Test_BibleRef">Test_BibleRef</a></p>
<hr />
<p>
  <BibleRef ref="Jeremiah 29:11" />
</p>
<p>
  <BibleRef ref="1 Corinthians 14:12, 26" />
</p>
<p>
  <BibleRef ref="Hebrews 8:1-6; 9:11-28" />
</p>
<p>
  <BibleRef ref="Hebrews 8:1-6, 9:11-28" />
</p>
<p>
  <BibleRef ref="Hebrews 8:1-6 and 9:11-28" />
</p>
<p>
  <BibleRef ref="Luke 23:54ff; 24:1" />
</p>
<p>
  <BibleRef ref="Luke 23:54-24:1" />
</p>
<p>
  <BibleRef ref="1 Corinthians 12:1-31" />
</p>

<script>
  // This tests the upstream tool at https://www.biblegateway.com/share/#reftag, which we are not using on this site.
  // We are using custom BibleRef component instead
  import { onMount } from 'svelte'
  import BGLinks from 'common/bible/bible_gateway_links'
  import BibleRef from './BibleGatewayReferenceTool_BibleRef.svelte'

  onMount(async () => {
    BGLinks.version = 'NKJV'
    BGLinks.linkVerses()
    document.querySelector('#main a').focus()
  })
</script>
