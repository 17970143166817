import * as React from 'react'
import { Address, AddressProps } from '../data_display/Address'

export interface ChurchInfoProps {
  user_added: boolean
  directory_url: string
  address: AddressProps
}

export function ChurchInfo(props: ChurchInfoProps) {
  return (
    <div>
      <Address {...props.address} />
      {props.user_added ? (
        <div />
      ) : (
        <div className="more_info_link">
          <a target="_blank" href="{props.directory_url}">
            View in Adventist Directory
          </a>
        </div>
      )}
    </div>
  )
}
