<!-- TODO: if read_only then ; extract custom <H2 readonly={false}> that renders Field/checkbox+slot or only pass through slot depending on readonly prop -->
<Field {name} type="checkbox" let:field validate={ifValidationsEnabled(requiredCheckbox)}>
  <RequiredCheckbox {field} {FormField$class}>
    <span slot="label">
      <h3 class="mdc-typography--headline3">
        <slot name="title" />
      </h3>
    </span>
  </RequiredCheckbox>
</Field>
<div class="item_text">
  <slot />
</div>

<script lang="ts">// final-form
import { Field } from 'svelte-final-form';
import { ifValidationsEnabled } from 'common/final-form';
// "This check box must be checked to continue."
const requiredCheckbox = (value) => value && (!Array.isArray(value) || value.length) ? undefined : 'This must be checked';
import RequiredCheckbox from '../../common/form/svelte-final-form/RequiredCheckbox.svelte';
export let name;
export let FormField$class = undefined;
</script>

<style lang="scss">h3 {
  color: #0072c6;
}

.item_text {
  margin-top: 14px;
  /* match the space used by checkbox: width + padding + h2 margin + fudge */
  margin-left: 47px;
  margin-right: 20px;
}
.item_text :global(.indent) {
  margin-left: 2em;
}
.item_text :global(ul) {
  margin: 0 1.5em 10px 40px;
}
.item_text :global(li) {
  line-height: 20px;
}
.item_text :global(li + li) {
  margin-top: 14px;
}</style>
