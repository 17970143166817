import * as React from 'react'
import { forwardRef, useCallback } from 'react'

import { parseIncompletePhoneNumber, formatIncompletePhoneNumber } from 'libphonenumber-js/core'
// import { RenderCount } from './RenderCount'

// Takes the country and metadata props and uses them to format the input value like the default InputBasic does.
// Seems to preserve caret position as well as InputBasic does, *without* any of the setState kludges that InputBasic employes. Moves caret to end  whenever the formatting adds an extra character like '(', ')', '-', or ' ', but that's the same behavior as with InputBasic.
export default function withFormattedPhoneNumber(Wrapped) {
  return React.memo(
    forwardRef<any, any>(({ value: initialValue, onChange, country, metadata, ...rest }, ref) => {
      const value = initialValue

      const format = useCallback(
        (value) => {
          // console.log(`formatIncompletePhoneNumber(${value}, ${country}) => `, formatIncompletePhoneNumber(value, country, metadata))
          return formatIncompletePhoneNumber(value, country, metadata)
        },
        [country, metadata]
      )
      const formattedValue = format(value)

      const onChangeWrapper = useCallback(
        (newValueFromEvent) => {
          let newValue = parseIncompletePhoneNumber(newValueFromEvent)

          // By default, if a value is something like `"(123)"`
          // then Backspace would only erase the rightmost brace
          // becoming something like `"(123"`
          // which would give the same `"123"` value
          // which would then be formatted back to `"(123)"`
          // and so a user wouldn't be able to erase the phone number.
          // Working around this issue with this simple hack.
          if (newValue === value) {
            if (format(newValue).indexOf(newValueFromEvent) === 0) {
              // Trim the last digit (or plus sign).
              newValue = newValue.slice(0, -1)
            }
          }

          onChange(newValue)
        },
        [value, format, onChange]
      )

      return (
        <>
          {/* <RenderCount /> */}
          <Wrapped
            type="tel"
            autoComplete="tel"
            {...rest}
            ref={ref}
            value={formattedValue}
            onChange={onChangeWrapper}
          />
        </>
      )
    })
  )
}
