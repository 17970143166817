<svelte:component this={wrapper} class={`InputGroup ${className}`} data-field-name={field.input.name}>
  <FormField class={FormField$class} {...FormFieldProps}>
    <span slot="label">
      <slot name="label" />
      {#if label}{label}{/if}
      {#if errorLocation === 'label'}
        <FieldErrors {field} when={errorWhen} />
      {/if}
    </span>

    <slot {field} />
  </FormField>

  <!-- <pre>{JSON.stringify(field.meta, null, 2)}</pre> -->
  {#if errorLocation === 'below'}
    <FieldErrors {field} when={errorWhen} />
  {/if}
</svelte:component>

<script lang="ts">// Variant of InputGroup for SMUI
import { Div } from 'svelte-elements';
import FormField from '@smui/form-field';
import { FieldErrors } from '@svelte/common/form/svelte-final-form/';
// import { useHandlers } from '@svelte/common'
// TODO: rename to FieldGroup or Fieldset or even Field (where there may be multiple variants called Field)?
// import { Input } from 'svelte-elements'
export let field;
export let wrapper = Div;
let className = undefined;
export { className as class };
export let FormField$class = undefined;
export let FormFieldProps = undefined;
export let label = undefined;
export let errorLocation = 'label';
export let errorWhen = 'submitFailed';
</script>
