<Form {onSubmit} {validate} {initialValues} let:form let:state>
  <form on:submit|preventDefault={form.submit}>
    <div>
      <Field name="firstName" let:field={{ input, handlers, meta }}>
        <label for="firstName">First Name</label>
        <input
          name={input.name}
          id={input.name}
          value={input.value}
          use:changeOnInput={handlers}
          type="text"
          placeholder="First Name"
        />
        {#if meta.touched && meta.error}
          <div>{meta.error}</div>
        {/if}
        <!-- <FieldSpy name="firstName" /> -->
        <FieldErrorsIndependent name="firstName" />
      </Field>

      <!-- You can prepare your Form Group Adapter with Label, Input, Errors -->
      <Field name="lastName" let:field>
        <div>
          <InputGroup {field} label="Last Name" type="text" placeholder="Last Name" />
        </div>
      </Field>
    </div>

    <!-- Example for svelte-select -->
    <Field name="color" let:field={{ input, handlers }}>
      <Select
        inputAttributes={{ ...input, autocomplete: `${input.name}-${Math.random()}` }}
        items={selectItems}
        selectedValue={input.value}
        on:blur={handlers.blur}
        on:focus={handlers.focus}
        on:select={({ detail }) => handlers.change(detail.value)}
      />
    </Field>
    <!-- isCreatable
        getOptionLabel -->

    <button type="submit" disabled={state.submitting}>Submit</button>
    or
    <button disabled={state.pristine} on:click={() => form.reset(initialValues)}>Reset</button>
  </form>

  <pre>state.values: {JSON.stringify(state.values, null, 2)}</pre>
  <pre>state: {JSON.stringify(state, null, 2)}</pre>
</Form>

<script>
  // svelte-final-form/examples/src/examples/ComparedToSvelteFormsLib.svelte
  import { Form, Field, useHandlers } from 'svelte-final-form'

  // Just for example
  import Select from 'svelte-select'
  import { changeOnInput } from '@svelte/common/form'
  import { InputGroup } from '@svelte/common/form/svelte-final-form'
  import FieldErrorsIndependent from '@svelte/common/form/svelte-final-form/FieldErrorsIndependent.svelte'
  import FieldSpy from '@svelte/common/form/svelte-final-form/FieldSpy.svelte'

  const selectItems = ['Green', 'Red', 'Black']

  const initialValues = {
    // firstName: "Alexey",
    // lastName: "Solilin",
    // color: "Red",
  }

  const onSubmit = async (values) => {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    console.log(JSON.stringify(values, undefined, 2))
  }

  const validate = (values) => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = 'Required'
    }
    if (!values.lastName) {
      errors.lastName = 'Required'
    }
    return errors
  }

  const getOptionLabel = (option, filterText) => {
    return option.isCreator ? `Add \"${filterText}\"` : option.label
  }
</script>
