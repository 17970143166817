<p>{message}</p>

<script>
  export let message

  import { onMount } from 'svelte'

  onMount(async () => {
    console.log('mounted')
  })
</script>
