{#if label}<label for={input.name}>{label}</label>{/if}

<slot {field}>
  <Input
    {field}
    id={input.name}
    {...$$restProps}
    on:input={(e) => {
      value = e.target.value
    }}
    on:input
    bind:element
  />
</slot>

<!-- <pre>{JSON.stringify(field.meta, null, 2)}</pre> -->
<FieldErrors {field} />

<script lang="ts">// https://github.com/jetrockets/svelte-final-form
// Adapted from: examples/src/common/InputGroup.svelte
/**
 * This is a wrapper for Input that also adds:
 * - a <label> (optional)
 * - <FieldErrors>
 * Pass this to Field's slot (or pass component={InputGroup} if you want all of this instead of just an Input.
 */
import { Input } from 'svelte-final-form';
import { FieldErrors } from '@svelte/common/form/svelte-final-form/';
// TODO: rename to FieldGroup or Fieldset or even Field (where there may be multiple variants called Field)?
// import { Input } from 'svelte-elements'
export let field;
export let label = undefined;
// Read-only/const. (That is, updating this from parent will have no effect.)
export let value = undefined;
export let element = undefined;
let input;
$: ({ input } = field);
</script>
