import languages_data from '../../../../../db/data/languages.json'

export const languageSelectOptions = Object.entries(languages_data)
  .map(([key, val]) => ({
    value: key,
    label: val.nativeName ? `${val.nativeName}` + (val.nativeName != val.name ? ` (${val.name})` : '') : val.name,
    group: val.group || 'Other languages',
  }))
  .sort(function (a, b) {
    const sort_key = (item) => `${item.group} ${item.label}`.toLowerCase()
    if (sort_key(a) < sort_key(b)) {
      return -1
    } else if (sort_key(a) > sort_key(b)) {
      return 1
    }
    return 0
  })

export const getLanguageFromBrowser = () => {
  return navigator.language.split('-')[0]
}
