import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

// https://github.com/catamphetamine/react-phone-number-input#min-vs-max-vs-mobile
import UpstreamPhoneInput from 'react-phone-number-input/max'
import 'react-phone-number-input/style.css'

import { assumedCountry } from 'common/phone_numbers/assumedCountry'
import { getExampleNationalNumberForCountry } from 'common/phone_numbers/getExampleNumberForCountry'
import { MuiInputForPhoneInput } from '@react/react-phone-number-with-mui/adaptMuiInputForPhoneInput'

//══════════════════════════════════════════════════════════════════════════════════════════
// PhoneInput using Material UI Input

// This is the main PhoneInput wrapper that most of the other phone input components in this app build on.
// It adds the following behaviors as default:
// - sets country (the default country) to a reasonable default so users can type in their phone number in the "national" number format that they are used to using instead of forcing them to type in their country code.
// - If we know or have a reasonable default country to use, then don't even show the "International" country option.
//   - But if we *don't* have a reasonable assumedCountry to use, then *do* show the "International" country option, so that it can be selected as a "no country yet" option. Otherwise, the upstream PhoneInput may guess the wrong country. For example, for the invalid input "+1234", it will select "Ascension Island" (probably because it's the first country it found with calling code "1"), even though that's not even the *main* country for this calling code (it probably should have defaulted to "United States" in this case).
// - displayInitialValueAsLocalNumber so that it displays it in that same national format again if value is loaded from the server
const UnstyledPhoneInput = ({ value, onCountryChange: onCountryChangeFromUser = (value) => {}, ...props }) => {
  const [country, setCountry] = useState(assumedCountry(value))
  useEffect(() => {
    onCountryChangeFromUser(country)
  }, [country])

  return (
    <>
      <UpstreamPhoneInput
        inputComponent={MuiInputForPhoneInput}
        country={country}
        displayInitialValueAsLocalNumber
        international={!country}
        onCountryChange={(value) => {
          setCountry(value)
          onCountryChangeFromUser(value)
        }}
        value={value}
        // placeholder={getExampleNationalNumberForCountry(country)}
        {...props}
      />
      {/* country: {country} */}
    </>
  )
}

// Fixes some styles on upstream PhoneInput component (from 'react-phone-number-input')
// Can be used as-is, or if you want to use with final-form, then use FinalPhoneInput instead.
export const PhoneInput = styled(UnstyledPhoneInput)`
  // Override bootstrap so that it doesn't interfere with react-phone-number-input
  img {
    // This was causing the black border around the flag icon to not align with the image itself
    // vendor/assets/stylesheets/bootstrap/_reset.scss:79
    // vertical-align: middle;
    vertical-align: unset;
  }

  .react-phone-number-input__icon {
    // Make the border transparent since I can't get it to line up perfectly (the actual image is shifted down by about 1 pixel from where it should line up with the border outline).
    border: 1px solid transparent;
  }
`

//══════════════════════════════════════════════════════════════════════════════════════════

//══════════════════════════════════════════════════════════════════════════════════════════
// Unused: PhoneInput using the default (upstream) input
// TODO: Rename to PhoneInput_BuiltinInput

// This is the main PhoneInput wrapper that most of the other phone input components in this app build on.
// It adds the following behaviors as default:
// - sets country (the default country) to a reasonable default so users can type in their phone number in the "national" number format that they are used to using instead of forcing them to type in their country code.
// - If we know or have a reasonable default country to use, then don't even show the "International" country option.
//   - But if we *don't* have a reasonable assumedCountry to use, then *do* show the "International" country option, so that it can be selected as a "no country yet" option. Otherwise, the upstream PhoneInput may guess the wrong country. For example, for the invalid input "+1234", it will select "Ascension Island" (probably because it's the first country it found with calling code "1"), even though that's not even the *main* country for this calling code (it probably should have defaulted to "United States" in this case).
// - displayInitialValueAsLocalNumber so that it displays it in that same national format again if value is loaded from the server
const UnstyledPhoneInputUpstreamInput = ({
  value,
  onCountryChange: onCountryChangeFromUser = (value) => {},
  ...props
}) => {
  const [country, setCountry] = useState(assumedCountry(value))
  useEffect(() => {
    onCountryChangeFromUser(country)
  }, [country])

  return (
    <>
      <UpstreamPhoneInput
        country={country}
        displayInitialValueAsLocalNumber
        international={!country}
        onCountryChange={(value) => {
          setCountry(value)
          onCountryChangeFromUser(value)
        }}
        value={value}
        // placeholder={getExampleNationalNumberForCountry(country)}
        {...props}
      />
      {/* country: {country} */}
    </>
  )
}

// Fixes some styles on upstream PhoneInput component (from 'react-phone-number-input')
// Can be used as-is, or if you want to use with final-form, then use FinalPhoneInput instead.
export const PhoneInputUpstreamInput = styled(UnstyledPhoneInputUpstreamInput)`
  .react-phone-number-input__country-select {
    &:focus {
    }
    // To match: .MuiInput-underline:after {
    // TODO: Get this to work
    &:after {
      content: ' ';
    }
    &:focus:after {
      content: ' ';
      border-bottom: 2px solid #303f9f; // blue
    }
  }
  .react-phone-number-input__country-select:focus + .react-phone-number-input__country-select-arrow {
    // Override:
    // color: #03B2CB;
    color: #303f9f;
  }

  // Override bootstrap so that it doesn't interfere with react-phone-number-input
  img {
    // This was causing the black border around the flag icon to not align with the image itself
    // vendor/assets/stylesheets/bootstrap/_reset.scss:79
    // vertical-align: middle;
    vertical-align: unset;
  }

  .react-phone-number-input__row {
    input[type='tel'] {
      // To match: .MuiInput color
      & {
        // border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        border-bottom: 1px solid #949494;
        margin-bottom: 1px; // So that it adds up to 2 to match :hover border width so that things don't appear to shift around as you switch between focus and unfocus.
      }
      // To match: .MuiInput-underline:hover:not(.Mui-disabled):before {
      // TODO: Fix. Disabled for now because it causes things to move around due to margin/border changes
      &:hover {
        // border-bottom: 2px solid rgba(0, 0, 0, 0.87);
        // margin-bottom: 0;
      }
      // To match: .MuiInput-underline:after {
      &:focus {
        border-bottom: 2px solid #303f9f; // blue
        margin-bottom: 0;
      }
    }
  }
`

//══════════════════════════════════════════════════════════════════════════════════════════
