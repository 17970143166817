<!-- TODO: For loading indicator: -->
<!-- <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"> -->

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-label-has-associated-control -->
<!-- on:submit|preventDefault={handleSubmit} -->
<RailsForm
  action={url}
  on:submit={(event) => onSubmit(event, form)}
  bind:formEl
  id="new_invitation"
  class="form-horizontal house-church-registration-form self"
>
  {#if utils.debug || utils.dev}
    <!-- svelte-ignore a11y-missing-attribute -->
    <p class="debug">
      <a
        role="button"
        tabindex="0"
        on:click={() => {
          debugFillIn(form, formEl)
        }}
      >
        Fill in this form for me (for testing)
      </a>
    </p>
  {/if}

  {#if localUtils.show_user_form && !$duplicate_on_house_church_name}
    <fieldset class="personal_info">
      <legend>Personal Info <img src={requiredIndicatorImage} alt="*" /></legend>
      <div class="control-group">
        <label class="control-label blue_bold" for="house_church_registration[registrant_attributes][email]"
          >E-mail Address
          <abbr class="required" title="Required">*</abbr>
        </label>
        <div class="controls">
          <Field
            name="house_church_registration[registrant_attributes][email]"
            validate={ifValidationsEnabled(validateEmail)}
            let:field
          >
            <InputGroup {field} type="email" placeholder="john.doe@example.com" />
          </Field>
          <!-- $duplicate_on_email: {JSON.stringify($duplicate_on_email, null, 2)} -->
        </div>
      </div>
      {#if !$duplicate_on_email}
        <div class="control-group">
          <label class="control-label blue_bold" for="house_church_registration[registrant_attributes][given_name]"
            >First Name
            <abbr class="required" title="Required">*</abbr>
          </label>
          <div class="controls">
            <Field
              name="house_church_registration[registrant_attributes][given_name]"
              validate={ifValidationsEnabled(required)}
              let:field
            >
              <InputGroup {field} placeholder="John" type="text" />
            </Field>
          </div>
        </div>
        <div class="control-group">
          <label class="control-label blue_bold" for="house_church_registration[registrant_attributes][surname]"
            >Last Name
            <abbr class="required" title="Required">*</abbr></label
          >
          <div class="controls">
            <Field
              name="house_church_registration[registrant_attributes][surname]"
              validate={ifValidationsEnabled(required)}
              let:field
            >
              <!-- <pre>meta: {JSON.stringify(meta, null, 2)}</pre> -->
              <InputGroup {field} placeholder="Doe" type="text" />
            </Field>
          </div>
        </div>

        <div class="privacy">
          When you register your house church, Simple Church does not share your information with anyone. Your
          information is protected.
        </div>
      {/if}
    </fieldset>
    <!-- personal_info -->
  {/if}

  {#if $duplicate_on_email}
    <fieldset class="duplicate_on_email">
      <legend>House Church Already Registered</legend>
      {#if $duplicate_on_email.house_church.type === 'HouseChurchRegistration'}
        <p>Based on the email address you entered, it looks like your house church is already registered!</p>
        <p>
          Are you ready for the next step? I would like to invite you to
          <a href="https://www.simplechurchathome.com/core4-online-training/">start the online training</a>
          and become part of this Simple Church Global Network.
        </p>
      {:else}
        <p>Based on the email address you entered, it looks like your simple church is already registered!</p>
        <p>
          You can
          <a href="https://my.simplechurchathome.com/users/sign_in?user[email]={registration.email}">sign in</a>
          (or
          <a href="https://my.simplechurchathome.com/users/password/new?user[email]={registration.email}"
            >reset your password</a
          >) using this same email address. Please check with your CORE team if you have any questions.
        </p>
      {/if}
    </fieldset>
  {/if}

  {#if !$duplicate_on_email}
    <fieldset class="house_church_info">
      <legend>House Church Info <img src={requiredIndicatorImage} alt="*" /></legend>

      <div class="no_house_church_yet_tip alert info alert-info">
        <h4>Don't have a house church yet?</h4>
        <p><a href="/get-started">Sign up</a> for the free online training and learn how to <a href="/get-started">launch a <em>new</em> house church.</a></p>
      </div>

      <div class="control-group">
        <Field
          name="house_church_registration[house_church_attributes][name]"
          let:field
          type="text"
          setIdToName
          validate={ifValidationsEnabled(validateHouseChurchName)}
        >
          <label class="control-label blue_bold" for={field.input.name}>House Church Name</label>
          <div class="controls">
            <InputGroup {field} placeholder="Include the words “House Church”" />
          </div>
        </Field>
      </div>

      {#if !$duplicate_on_house_church_name}
        <div class="control-group house_church_registration_duration_of_membership">
          <label class="control-label blue_bold" for="house_church_registration[duration_of_membership]"
            >How long have you been part of this house church?</label
          >
          <div class="controls">
            <FieldInputAndErrors
              name="house_church_registration[duration_of_membership]"
              type="text"
              bind:element={duration_of_membership_el}
            />
          </div>
        </div>
      {/if}

      {#if !$duplicate_on_house_church_name}
        <div class="control-group radio_buttons house_church_registration_is_user_a_leader">
          <label class="control-label blue_bold" for="house_church_registration.is_user_a_leader.true"
            >Are you one of the leaders?</label
          >
          <div class="controls">
            <Field
              name="house_church_registration[is_user_a_leader]"
              type="radio"
              value={'true'}
              let:field
              id="house_church_registration.is_user_a_leader.true"
            >
              <label class="radio">
                <Input {field} />
                Yes
              </label>
            </Field>
            <Field name="house_church_registration[is_user_a_leader]" type="radio" value={'false'} let:field>
              <label class="radio">
                <Input {field} />
                No
              </label>
              <FieldErrors {field} />
            </Field>
          </div>
        </div>
      {/if}
    </fieldset>
  {/if}

  {#if $duplicate_on_house_church_name}
    <fieldset class="duplicate_on_house_church_name">
      <legend>House Church Already Registered?</legend>
      <p>
        It is possible that your house church is already registered. There is a
        <b>{$duplicate_on_house_church_name.house_church.name}</b>
        that already exists in
        {$duplicate_on_house_church_name.house_church.address_city_state_country}.
      </p>
      <p>
        If you are sure that your house church location is a different one than the one that's already registered, then
        you may continue the registration.
      </p>
      <div>
        <!-- svelte-ignore a11y-missing-attribute -->
        <button
          type="button"
          class="btn btn-primary btn-large"
          id="continue_registration_button"
          on:click={handleContinueRegistration}>Continue Registration</button
        >
        <a href="http://www.simplechurchathome.com/" style="margin-left: 10px">Cancel Registration</a>
      </div>
    </fieldset>
  {/if}

  {#if !possibly_duplicate_house_church}
    <!-- <pre>statesData: {JSON.stringify(statesDataDebug, null, 2)}</pre> -->
    <fieldset class="physical_address">
      <legend>House Church Address <img src={requiredIndicatorImage} alt="*" /></legend>

      <div class="control-group country_code">
        <Field
          name="house_church_registration[house_church_attributes][address_attributes][country_code]"
          validate={ifValidationsEnabled(required)}
          setIdToName
          let:field
        >
          <label class="select required control-label blue_bold" for={field.input.id}>
            Country
            <abbr class="required" title="Required">*</abbr>
          </label>
          <div class="controls">
            <CountrySelect
              {...field.input}
              on:change={(event) => {
                field.handlers.change(event)
              }}
              on:blur={field.handlers.blur}
            />
            <FieldErrors {field} />
          </div>
        </Field>
      </div>
      <div class="control-group text required house_church_registration_address">
        <label
          class="text required control-label blue_bold"
          for="house_church_registration[house_church_attributes][address_attributes][address]"
          >Street Address
          <abbr class="required" title="Required">*</abbr>
        </label>
        <div class="controls">
          <FieldInputAndErrors
            name="house_church_registration[house_church_attributes][address_attributes][address]"
            setIdToName
            validate={ifValidationsEnabled(required)}
            component="textarea"
            placeholder="123 Main St."
            cols="40"
            rows="2"
          />
        </div>
      </div>
      <div class="control-group required house_church_registration_city">
        <label
          class="required control-label blue_bold"
          for="house_church_registration[house_church_attributes][address_attributes][city]"
          >City
          <abbr class="required" title="Required">*</abbr>
        </label>
        <div class="controls">
          <FieldInputAndErrors
            name="house_church_registration[house_church_attributes][address_attributes][city]"
            setIdToName
            validate={ifValidationsEnabled(required)}
            type="text"
            placeholder="City"
          />
        </div>
      </div>
      <Field
        name="house_church_registration[house_church_attributes][address_attributes][state]"
        validate={ifValidationsEnabled(stateRequiredValidator)}
        setIdToName
        let:field
      >
        <div class="control-group state_field" class:hidden={statesLoadedButEmpty}>
          <label class="blue_bold control-label" for={field.input.id}
            >State/Province
            <abbr class="required" class:invisible={!stateRequired} title="Required">*</abbr>
          </label>
          <div class="controls">
            <StateSelect
              {...field.input}
              countryCode={country_code}
              on:load={async (event) => {
                const newStatesData = event.detail
                // TODO: Find a cleaner way to do this. Maybe static variable in StateSelect ??
                // Don't reset state field if there is no change in states data. This works around issue where if StateSelect is unmounted, and remounted, it triggers on:load again.
                // ... which maybe wouldn't be a problem except for our other workaround where it resets state value in order to trigger validation.
                // To test:
                // - Select a country that has states, and blur State so it shows required error
                // - Change country to Antarctica and confirm that not only is State field hidden, but State error went away too.
                if (JSON.stringify(statesData) === JSON.stringify(newStatesData)) {
                  console.log('on:load: states already loaded', field.input.value)
                } else {
                  statesData = event.detail
                  console.log(
                    'on:load: states.length now',
                    statesData.states.length,
                    'value:',
                    typeof field.input.value,
                    field.input.value
                  )
                  // console.log('statesData.restrictedToList now', statesData.restrictedToList)
                  await tick()
                  form.mutators.revalidate(field.input.name)
                }
              }}
              on:change={(event) => {
                field.handlers.change(event)
              }}
              on:blur={field.handlers.blur}
            />
            <FieldErrors {field} />
          </div>
          <!-- To debug and confirm that setting country to one with no states (like Antarctica) *immediately* causes error on state to be cleared. (Because it would be really bad if we hid this field but it was still invalid and prevented form from submitting.) -->
          <!-- <div>stateRequired: {stateRequired}; valid: {field.meta.valid}; error: {field.meta.error}</div> -->
        </div>
      </Field>
      <div class="control-group required">
        <label
          class="required control-label blue_bold"
          for="house_church_registration[house_church_attributes][address_attributes][postal_code]"
          >Postal/Zip Code
          <abbr class="required" title="Required">*</abbr></label
        >
        <div class="controls">
          <FieldInputAndErrors
            setIdToName
            name="house_church_registration[house_church_attributes][address_attributes][postal_code]"
            validate={ifValidationsEnabled(required)}
            placeholder="12345"
            type="text"
          />
        </div>
      </div>
    </fieldset>
    <!-- physical_address -->
  {/if}

  {#if !possibly_duplicate_house_church}
    <fieldset class="newsletter_subscription">
      <legend>Subscribe to Simple Church’s newsletter, 🎈 RIPPLES</legend>

      <div class="control-group">
        <Field
          name="subscribe_to_newsletter"
          let:field
          type="checkbox"
          setIdToName
          validate={ifValidationsEnabled(subscribeRequired)}
        >
          <Input {field} value="1" />
          <label class="blue_bold" style="display: inline" for={field.input.name}
            >I understand that I will be added to the Simple Church Global Network newsletter, 🎈 Ripples, and I can
            unsubscribe at any time.
          </label>
          <FieldErrors {field} />
        </Field>
      </div>

      <OtherPeopleToSubscribe />
    </fieldset>
  {/if}

  <!-- {#if utils.debug || utils.dev}
    <pre>state.values: {JSON.stringify(state.values, null, 2)}</pre>
  {/if} -->

  {#if utils.debug || utils.dev}
    <pre class="debug">state.initialValues: {JSON.stringify(state.initialValues, null, 2)}</pre>
    <pre class="debug">state.values: {JSON.stringify(state.values, null, 2)}</pre>
    <pre class="debug">state.errors: {JSON.stringify(state.errors, null, 2)}</pre>
    <pre class="debug">state.submitErrors: {JSON.stringify(state.submitErrors, null, 2)}</pre>
    <!-- <pre>state: {JSON.stringify(state, null, 2)}</pre> -->
  {/if}

  <FormSubmissionErrors />
  <div class="form-actions">
    <button class="btn btn-primary btn-large" type="submit"> {submit_button_text} </button>
  </div>
</RailsForm>

<script lang="ts">// TODO:
// - loading indicator icon while states loading (low priority)
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g;
import { tick } from 'svelte';
import { utils as localUtils, debugFillIn, duplicate_on_email, duplicateOnEmailMessage, validateEmail, duplicate_on_house_church_name, validateHouseChurchName, subscribeRequired, } from '.';
import { utils, transformKeys } from '../../common/utils';
import { RailsForm } from '@svelte/common/form';
import CountrySelect from '@svelte/common/form/fields/CountrySelect.svelte';
import StateSelect from '@svelte/common/form/fields/StateSelect.svelte';
import OtherPeopleToSubscribe from './OtherPeopleToSubscribe.svelte';
import './HowDidYouHearResponse.svelte';
import '@svelte/common/form/fields/PhoneInput.svelte';
import { validatePossiblePhoneNumberForCountry } from 'common/phone_numbers/validatePhoneNumber';
const ourValidatePossiblePhoneNumberForCountry = (value) => {
    return validatePossiblePhoneNumberForCountry(phoneCountry)(value);
};
import arrayMutators from 'final-form-arrays';
import create_focusOnError from 'final-form-focus';
import { ifValidationsEnabled, required, revalidate, clearError, setSubmitErrors, } from 'common/final-form';
import { createForm, Field, Input, whenValueChanges } from 'svelte-final-form';
import { InputGroup, FieldErrors, FormSubmissionErrors } from '@svelte/common/form/svelte-final-form/';
import FieldInputAndErrors from '@svelte/common/form/svelte-final-form/FieldInputAndErrors.svelte';
export let initialValues = { house_church_registration: {} };
export let submitErrors = {};
submitErrors = submitErrors
    ? transformKeys(submitErrors, (key) => key
        .replace(/\b(registrant|house_church|address)\./g, '$1_attributes.')
        .replace(/\b(country)\b/g, 'country_code'))
    : {};
export let url = '/house_church_registrations/';
const requiredIndicatorImage = require('images/asterisk.png');
// { final-form
const onSubmit = (event, form) => {
    // I wish this had a useful (valid/invalid) return value that we could check.
    form.submit();
    if (form.getState().submitFailed) {
        event.preventDefault();
    }
    else {
        // Allow form to submit normally to server
    }
};
// This is called after user submits form, if all form validations pass. This is when we submit that data to the backend.
// It used to submit it via XHR.
const onValidSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
    console.log('onValidSubmit', JSON.stringify(values, undefined, 2));
    console.log('onValidSubmit', form.getState());
    // console.log('submitted', JSON.stringify(values, undefined, 2))
    // return await submit(values)
});
const [form, stateStore] = createForm({
    onSubmit: onValidSubmit,
    decorators: [create_focusOnError()],
    initialValues: initialValues,
    mutators: Object.assign(Object.assign({}, arrayMutators), { revalidate,
        clearError,
        setSubmitErrors }),
});
form.mutators.setSubmitErrors(Object.assign({}, submitErrors));
$: state = $stateStore;
$: registration = state.values.house_church_registration;
const handleSubmit = (arg) => __awaiter(void 0, void 0, void 0, function* () {
    yield form.submit();
});
// } final-form
let phoneCountry;
// TODO: StatesData type
let statesData = {};
$: hasStates = statesData.states && statesData.states.length > 0;
$: statesLoadedButEmpty = statesData.states && statesData.states.length === 0;
$: stateRequired = hasStates && statesData.restrictedToList;
// This just makes it conditional, since we can't currently update validate prop of Field
$: stateRequiredValidator = (value) => {
    // Can't rely on hasStates from reactive statement to be current.
    // The value of statesData is updated immediately in on:load, but the value of reactive hasStates may still be out-of-date when state validator called.
    // Probably because it is the changing of country field that _immediately_ triggers validations to be run.
    // StateSelect then gets the updated country_code (reactively):
    //          countryCode={state.values.house_church_registration[house_church_attributes][address_attributes]country_code}
    // It then triggers on:load with the list of states. In on:load:
    //   We synchronously update the regular let statesData variable.
    //   That doesn't automatically trigger validation so we have to re-trigger it from there.
    // This immediately and synchronously causes the state validator to be run, but that is before any reactive assignments have had a chance to update, so only statesData itself is current at this point, and not anything derived from it.
    // Unless we await tick() before triggering validation. That allows all reactive assignments to run *before* re-triggering validation.
    // const _hasStates = statesData.states && statesData.states.length > 0
    // console.log('stateRequiredValidator:', statesData?.states?.length, 'hasStates', hasStates, _hasStates, 'value:', value)
    return hasStates && required(value);
};
let formEl;
let duration_of_membership_el;
//══════════════════════════════════════════════════════════════════════════════════════════════════
// { Validations
//──────────────────────────────────────────────────────────────────────────────────────────────────
const whenEmailErrorChanges = whenValueChanges((_b = (_a = $stateStore.errors) === null || _a === void 0 ? void 0 : _a.house_church_registration) === null || _b === void 0 ? void 0 : _b.email, (value) => {
    // console.log('new value:', value)
    if (value === duplicateOnEmailMessage) {
        // As soon as we set this error, we want it to show up immediately so they can get feedback *as they're typing*.
        // We could probably find a way to override the conditions for showing error message, but this way works too:
        // Since errors currently only show up if the field has been touched, we blur it to cause it to be touched.
        form.blur('house_church_registration.email');
    }
    else {
        // Clear duplicate_on_email if the error on email is either:
        // - nullish (no error) or
        // - a different error (an "earlier" error in the composeValidators chain)
        // duplicate_on_email being set is a side effect from checkForDuplicateOnEmail;
        // since final-form only has one validation error on a field at a time, if this
        // particular error message is no longer the error being shown, we want the
        // duplicate_on_email variable to be kept in sync.
        $duplicate_on_email = null;
    }
});
$: whenEmailErrorChanges((_d = (_c = $stateStore.errors) === null || _c === void 0 ? void 0 : _c.house_church_registration) === null || _d === void 0 ? void 0 : _d.email);
// $: console.log('duplicate_on_email', $duplicate_on_email)
//──────────────────────────────────────────────────────────────────────────────────────────────────
const handleContinueRegistration = () => __awaiter(void 0, void 0, void 0, function* () {
    $duplicate_on_house_church_name = null;
    yield tick();
    duration_of_membership_el === null || duration_of_membership_el === void 0 ? void 0 : duration_of_membership_el.focus();
});
//══════════════════════════════════════════════════════════════════════════════════════════════════
//  Derived values
$: country_code = (_g = (_f = (_e = state.values.house_church_registration) === null || _e === void 0 ? void 0 : _e.house_church_attributes) === null || _f === void 0 ? void 0 : _f.address_attributes) === null || _g === void 0 ? void 0 : _g.country_code;
$: submit_button_text = state.submitting ? 'Submitting' : 'Register Our House Church';
$: possibly_duplicate_house_church = $duplicate_on_email || $duplicate_on_house_church_name;
// $: console.log('possibly_duplicate_house_church', possibly_duplicate_house_church)
//══════════════════════════════════════════════════════════════════════════════════════════════════
</script>

<style lang="scss">:global(.self textarea) {
  /* Fixes styles from bootstrap: textarea was fixed height: 28px */
  height: 56px;
}

:global(.self select[name="house_church_registration.country_code"]),
:global(.self select[name="house_church_registration.state"]) {
  width: 460px;
}

.no_house_church_yet_tip p {
  margin-bottom: 0;
}

.duplicate_on_email,
.duplicate_on_house_church_name {
  background-color: hsl(0deg, 70%, 85%);
}
.duplicate_on_email legend,
.duplicate_on_house_church_name legend {
  background-color: hsl(0deg, 70%, 90%);
}</style>
