import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css' // optional for styling
import 'tippy.js/themes/light.css'
// tippy.setDefaultProps({ animation: false });

export function useTippy(elem, params) {
  let tippyInstance
  let cmp
  let { content, props, onShow, ...opts } = params

  // defaults
  opts = {
    trigger: 'click mouseenter focus',
    allowHTML: true,
    delay: [0, 0],
    // So that if they trigger the popover with hover, they can move the mouse
    // down into its contents without it disappearing on them.
    // Ref: https://atomiks.github.io/tippyjs/v6/accessibility/#interactivity
    interactive: true,
    // However! Since it appends the tippy to the parentNode by default, if you
    // tabbed between the links, it wouldn't let you tab into the contents (how
    // "interactive" is supposed to work) _except_ for the very last link in the
    // paragraph, which seems inconsistent and surprising, and prevents you from
    // tabbing from the last link directly to the next checkbox, which is
    // probably what they expect/want to do. (They say "You should wrap the
    // reference element in its own parent element (<div> or <span>) if it's not
    // the only focusable sibling element." But when I tried wrapping the target
    // <a> in a span, it broke the layout of the paragraph when the tippy was
    // triggered, because it added a display: block div inside of the span.) So
    // we prevent any kind of tabbing into the contents by appending to body
    // instead of parent. "interactive" is only enabled for use with the mouse,
    // then, and not for use with the keyboard.
    appendTo: document.body,
    touch: 'hold',
    placement: 'bottom',
    theme: 'light',
    onShow: (instance) => {
      const contentEl = instance.popper.querySelector('.tippy-content')
      let childEl = contentEl?.children[0]
      // console.log('onShow:', contentEl, childEl)
      if (childEl && childEl.style.display == 'none') {
        childEl.style.display = 'block'
      }
      if (onShow) onShow()
    },
    ...opts,
  }

  // console.log(typeof content, content)
  if (typeof content === 'function') {
    opts.onCreate = (instance) => {
      cmp = new content({
        target: instance.popper.querySelector('.tippy-content'),
        props,
      })
    }
  } else {
    opts.content = content
  }

  function tryToInit(params) {
    ;({ content } = params)
    if (tippyInstance) {
      return
    } // already initialized
    if (!content) {
      return
    } // don't yet have what we need to initialize

    // console.log('useTippy: tryToInit:', typeof content, content)
    opts.content = content
    tippyInstance = tippy(elem, opts)
  }

  tryToInit(params)

  return {
    update(params) {
      tryToInit(params)
      if (cmp) cmp.$set(params.props)
    },
    destroy() {
      tippyInstance.destroy()
      if (cmp) {
        cmp.$destroy()
      }
    },
  }
}
