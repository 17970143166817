import * as React from 'react'

export interface AddressProps {
  address?: string
  city?: string
  state?: string
  postal_code?: string
  country?: string
}

export function Address(props: AddressProps) {
  const address_lines = props.address ? props.address.split('\n') : []
  const city_comma =
    typeof props.city != 'undefined' && typeof props.state != 'undefined' && props.city && props.state ? ',' : ''
  return (
    <div>
      {address_lines.map((line, i) => (
        <div>{line}</div>
      ))}
      <div>
        {props.city}
        {city_comma}&nbsp;
        {props.state} {props.postal_code}
      </div>
      <div>{props.country}</div>
    </div>
  )
}
