<div class="ml-2 mb-2" style="margin-bottom: 2em">
  {#if cancel_training}
    <div class="control-group">
      <div class="controls">
        <input
          type="checkbox"
          name="cancel_training"
          id="cancel_training"
          value="1"
          checked={cancel_training.checked}
          on:input={handleTop2ItemClick}
        />
        <label class="blue_bold" for="cancel_training"
          >Please cancel {cancel_training.my_or_our_training} PhaseONE training. {cancel_training.my_or_our_training ==
          'our'
            ? 'We are'
            : 'I am'} not going to continue.
        </label>
      </div>
    </div>
  {/if}
  {#if cancel_house_church_registration}
    <div class="control-group">
      <div class="controls">
        <input
          type="checkbox"
          name="cancel_house_church_registration"
          id="cancel_house_church_registration"
          value="1"
          checked={cancel_house_church_registration.checked}
          on:input={handleTop2ItemClick}
        />
        <label class="blue_bold" for="cancel_house_church_registration"
          >Please remove our “{cancel_house_church_registration.house_churches_sentence}” from the
          <a target="_blank" href="/house_church_finder">Find a House Church</a> map.
        </label>
      </div>
    </div>
  {/if}
  {#if cancel_newsletter_subscription}
    <div class="control-group">
      <div class="controls">
        <input
          type="checkbox"
          name="cancel_newsletter_subscription"
          id="cancel_newsletter_subscription"
          value="1"
          checked={cancel_newsletter_subscription.checked}
          on:input={checkDependents}
        />
        <label class="blue_bold" for="cancel_newsletter_subscription"
          >Please cancel my 🎈 RIPPLES Newsletter subscription.
        </label>
      </div>
    </div>
  {/if}
  {#if !cancel_training && !cancel_house_church_registration && !cancel_newsletter_subscription}
    (You are not currently signed up for anything.)
  {/if}
</div>

<script lang="ts">export let cancel_training = undefined;
export let cancel_house_church_registration = undefined;
export let cancel_newsletter_subscription = undefined;
function handleTop2ItemClick(event) {
    const checked = event.target.checked;
    if (event.target.name == 'cancel_training') {
        cancel_training = Object.assign(Object.assign({}, cancel_training), { checked });
    }
    if (event.target.name == 'cancel_house_church_registration') {
        cancel_house_church_registration = Object.assign(Object.assign({}, cancel_house_church_registration), { checked });
    }
    if (!checked) {
        cancel_newsletter_subscription = Object.assign(Object.assign({}, cancel_newsletter_subscription), { checked: false });
    }
}
function checkDependents(event) {
    const checked = event.target.checked;
    cancel_newsletter_subscription = Object.assign(Object.assign({}, cancel_newsletter_subscription), { checked });
    if (checked) {
        if (cancel_training) {
            cancel_training = Object.assign(Object.assign({}, cancel_training), { checked: true });
        }
        if (cancel_house_church_registration) {
            cancel_house_church_registration = Object.assign(Object.assign({}, cancel_house_church_registration), { checked: true });
        }
    }
}
</script>

<style lang="scss"></style>
