import isPossiblePhoneNumber from 'common/phone_numbers/isPossiblePhoneNumber'
import { isValidPhoneNumber } from 'react-phone-number-input/max'
import { parsePhoneNumber } from 'react-phone-number-input/max'
import countries, { getCountryName } from 'common/countries'
import { getExampleNationalNumberForCountry } from 'common/phone_numbers/getExampleNumberForCountry'
import { required, composeValidators } from 'common/final-form'

// Tries to parse country from the phone number. Use this only when you're not
// watching onCountryChange to get the actual current selected country.
// It is not always possible to parse country from a phone number because
// multiple countries may share a country calling code, and we may not have
// enough digits yet to disambiguate which country it is for. That's why it is
// better to use the country they've actually selected from the country select.
export function validatePossiblePhoneNumberParsingCountry(value) {
  if (!value) return

  const parsedPhone = parsePhoneNumber(value || '')
  // console.log('validatePhoneNumber: parsedPhone:', parsedPhone)

  return validatePossiblePhoneNumberForValueCountry(value, parsedPhone && parsedPhone.country)
}

// Only checks that it's the expected length, not the digits match the expected pattern exactly.
export function validatePossiblePhoneNumberForValueCountry(value, countryCode) {
  if (!value) return // Requiredness should be validated separately
  if (isPossiblePhoneNumber(value)) return

  return phoneNumberInvalidForCountry(value, countryCode)
}
export const validatePossiblePhoneNumberForCountry = (countryCode) => (value) =>
  validatePossiblePhoneNumberForValueCountry(value, countryCode)

// This is a stricter check than simply "is value possible?".
// Since we can't be 100% that this validation check is correct, it is recommended to use this only
// as a *warning* and not as a true validation that prevents submission.
//
// TODO: Show the *reason* it was not possible, like they do for some reasons on https://rawgit.com/googlei18n/libphonenumber/master/javascript/i18n/phonenumbers/demo-compiled.html
export function validateValidPhoneNumberForCountry(value, countryCode) {
  if (!value) return // Requiredness should be validated separately
  if (isValidPhoneNumber(value)) return

  // Since we can't be 100% that this validation check is accurate and we don't want to come across
  // as 100% confident when we tell them it is not valid, in case we are wrong, use this less-confident language...
  return phoneNumberInvalidForCountry(value, countryCode, `does not appear to be`)
}

export function phoneNumberInvalidForCountry(value, countryCode, isNotText = 'is not') {
  const countryName = getCountryName(countryCode)
  const mainText = `This ${isNotText} a valid phone number`
  if (countryCode) {
    return (
      `${mainText} for ${countryName}. ` +
      `Please make sure that you've selected the correct country and have entered the phone number correctly. ` +
      `(An example of a valid number would be: ${getExampleNationalNumberForCountry(countryCode)})`
    )
  } else {
    return `${mainText}.`
  }
}

export const validateRequiredPhoneNumberParsingCountry = composeValidators(
  required,
  validatePossiblePhoneNumberParsingCountry
)
export const validateRequiredPhoneNumberForCountry = (countryCode) => (value) =>
  required(value) || validatePossiblePhoneNumberForValueCountry(value, countryCode)
