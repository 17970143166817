<!-- TODO: try to use bind:targetEl here; don't think it's allowed; since it's too easy to forget to call init, how about we just do onMount and get reference that way instead? -->
<slot name="target" init={initTarget} />
<div style="display: none;" use:contentEl>
  <!-- 
  <Paper>
    <Content>
  -->
  <slot />
  <!--
    </Content>
  </Paper>
  -->
</div>

<script>
  // See also: app/javascript/@svelte/common/PopoverWithPopper.svelte
  // TODO: publish on npm: svelte-tippy
  // TODO: custom theme similar to ~/code/js/tippyjs/src/scss/themes/light.scss but that matches box-shadow styles of MenuSurface
  // TODO: make slot name="target" be the default. Had error when I tried that. (See stash)

  let targetEl = undefined
  let opts = $$restProps

  // import Paper, { Content } from '@smui/paper'
  import { useTippy } from './useTippy.js'

  const initTarget = (_targetEl) => {
    // console.log('initTarget', _targetEl)
    targetEl = _targetEl
  }
  const contentEl = (contentEl) => {
    // console.log('contentEl:', targetEl, {content: contentEl, ...opts})
    if (!targetEl) {
      throw new Error('targetEl missing; did you forget to call init?')
    }
    return useTippy(targetEl, { content: contentEl, ...opts })
  }
</script>

<style lang="scss">:global(.tippy-content) {
  padding: unset;
}

:global(.tippy-arrow) {
  z-index: -1;
}</style>
