method:
{JSON.stringify(method)}
params:
<pre>{JSON.stringify(params, null, 2)}</pre>

<RailsForm action={window.location.pathname} method="get" bind:formEl>
  <div>
    <input type="text" name="message" value="get" />
    <button name="button" type="submit" value="get_button">Submit</button>
  </div>
</RailsForm>

<RailsForm action={window.location.pathname} method="patch">
  <div>
    <input type="text" name="message" value="patch" />
    <button name="button" type="submit" value="patch_button">Submit</button>
  </div>
</RailsForm>

<script>
  import { onMount } from 'svelte'
  import { RailsForm } from '@svelte/common/form'

  export let method
  export let params

  let formEl = undefined

  onMount(() => {
    console.log('Test', formEl)
    console.log('Test', formEl.querySelector('input'))
  })
</script>

<style>
  :global(form) {
    border: 1px solid darkgray;
    margin-bottom: 1em;
  }
</style>
