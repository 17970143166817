<p>Compare to <a href="/test_svelte/Test_BibleGatewayReferenceTool">Test_BibleGatewayReferenceTool</a></p>
<hr />
<p>
  <BibleRef ref="Jeremiah 29:11" />
</p>
<p>
  <BibleRef ref="1 Corinthians 14:12, 26" />
</p>
<p>
  <BibleRef ref="Hebrews 8:1-6; 9:11-28" />
</p>
<p>
  <BibleRef ref="Hebrews 8:1-6, 9:11-28" />
</p>
<p>
  <BibleRef ref="Hebrews 8:1-6 and 9:11-28" />
</p>
<p>
  <BibleRef ref="Luke 23:54ff; 24:1" />
</p>
<p>
  <BibleRef ref="Luke 23:54-24:1" />
</p>
<p>
  <BibleRef ref="1 Corinthians 12:1-31" />
  <!-- Seems to detect that it's the whole chapter: "reference_display" : "1 Corinthians 12", but only shows the first 4.5 verses -->
</p>

<!-- <p>Passing slot content (didn't end up needing; BibleRef_Single.svelte still supports it, but currently requires passing a parsed BibleRef struct):
  <BibleRefReal ref="Jeremiah 29:11">29:11</BibleRefReal></p> -->
<script>
  import { onMount } from 'svelte'
  import BibleRef from './BibleRef_BibleRef.svelte' // Example wrapper
  import BibleRefReal from '@svelte/common/BibleRef.svelte'

  onMount(async () => {
    document.querySelector('#main a').focus()
  })
</script>
