<Button type="submit" color="primary" variant="raised" {...$$restProps}>
  <Label>
    <slot />
    {label_text}
  </Label>
</Button>

<script>
  import Button, { Label, Icon as ButtonIcon } from '@smui/button'

  export let label_text = ''
</script>

<style lang="scss">:global(.mdc-button) {
  text-transform: none;
}</style>
