<!-- svelte-ignore a11y-no-onchange -->
<select on:input on:blur on:change disabled={!states} bind:value {...$$restProps}>
  {#if countryCode}
    {#if states}
      <option value="" />
      {#each states as state}
        <option value={state.code}>{state.name}</option>
      {/each}
    {:else}
      <option value="">Loading...</option>
      <!-- <Icon class="material-icons" style="margin-right: 12px;">loading</Icon> -->
    {/if}
  {:else}
    <option value="">Please select a country above first</option>
  {/if}
</select>

<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// See also: app/assets/javascripts/addresses.js.coffee
// To test:
// - Christmas Island: has no states
// TODO: make state optional if country_codes_to_user_entered_states
// TODO: allow user entry
import axios from 'axios';
import { createEventDispatcher } from 'svelte';
export let countryCode;
export let value;
const dispatch = createEventDispatcher();
export let loading = false;
export let restrictedToList = undefined;
let states = undefined;
const load = (countryCode) => __awaiter(void 0, void 0, void 0, function* () {
    if (countryCode) {
        states = undefined;
        loading = true;
        // console.log(countryCode, loading)
        const result = yield axios.get(`/countries/${countryCode}/states.json`);
        const data = yield result.data;
        loading = false;
        states = [
            ...(data.country_codes_to_states[countryCode] || []),
            ...(data.country_codes_to_user_entered_states[countryCode] || []),
        ];
        restrictedToList = !!data.country_codes_to_states[countryCode];
        // console.log(states)
    }
    else {
        restrictedToList = undefined;
        states = undefined;
    }
    dispatch('load', {
        restrictedToList,
        states,
    });
});
$: load(countryCode);
</script>
