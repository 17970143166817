import PickACoachPage from './PickACoachPage/PickACoachPage.svelte'
import MissionaryCommitmentPage from './MissionaryCommitmentPage/MissionaryCommitmentPage.svelte'
import BibleBasics from './BibleBasics/BibleBasics.svelte'

import ContinueButton from './ContinueButton.svelte'

export default {
  PickACoachPage,
  MissionaryCommitmentPage,
  BibleBasics,

  ContinueButton,
}
