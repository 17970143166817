<!-- svelte-ignore a11y-missing-content -->

<Tippy onShow={loadPassage}>
  <a slot="target" href={link_url} let:init use:init on:click|preventDefault target="_blank" class="bible_reference"
    ><slot>{ref.matched_text}</slot></a
  >
  <h3 class="title" />
  <div class="body">
    {#if passage_data}
      <div class="passage">
        {@html passage_data.clean}
      </div>
    {:else}Loading...{/if}
    <div class="reference">
      —
      <a href={link_url} target="_blank" class="bible_reference">{reference_display}</a>
      <span class="version"> (<a href={version_url} target="_blank">{version}</a>) </span>
    </div>
  </div>
  <div class="footer">
    <a class="bg_popup-bglogo" href="{biblegateway_base_url}/" rel="nofollow" target="_blank">
      <span class="text_fallback"> BibleGateway </span>
    </a>
  </div>
</Tippy>

<script context="module">
  const cache = {}
</script>

<script lang="ts">// Tested by: app/javascript/@svelte/test/BibleRef.svelte
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';
import DOMPurify from 'dompurify';
import { Tippy } from '@svelte/common';
import 'common/bible/parse_bible_ref';
export let ref;
export let version = 'NKJV';
let link_url;
let data_url;
let popover;
let passage_data;
let reference_display;
const biblegateway_base_url = 'https://www.biblegateway.com';
if (ref) {
    link_url = `${biblegateway_base_url}/passage/?search=${ref.reference}&version=${version}&src=tools`;
    data_url = `${biblegateway_base_url}/share/tooltips/data/?search=${ref.reference}&version=${version}`;
}
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const loadPassage = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!ref.reference) {
        return;
    }
    if (cache[ref.reference]) {
        // console.log('cached for', ref.reference, cache[ref.reference])
        passage_data = cache[ref.reference];
        return;
    }
    const result = yield axios.get(data_url, { adapter: jsonpAdapter });
    // await sleep(500)
    passage_data = yield result.data;
    passage_data.clean = DOMPurify.sanitize(passage_data.text, { USE_PROFILES: { html: true } });
    cache[ref.reference] = passage_data;
    // console.log(`data for ${ref.reference}`, passage_data)
});
$: {
    if (passage_data) {
        reference_display = passage_data.reference_display;
        if (passage_data.reference_display != ref.reference) {
            console.log(ref.reference, '!==', passage_data.reference_display);
        }
    }
    else {
        reference_display = ref.reference;
    }
}
$: version_url = `${biblegateway_base_url}${(passage_data === null || passage_data === void 0 ? void 0 : passage_data.version_url) || '/'}`;
</script>

<style lang="scss">a.bible_reference {
  color: #004d85;
  text-decoration: none;
  border-bottom: 1px dotted rgba(119, 119, 119, 0.631);
}

:global(.tippy-box) {
  min-width: 36vw;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 6px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
:global(.tippy-box) .body {
  padding: 1em;
}
:global(.tippy-box) .reference {
  margin-top: 0.5em;
  text-align: right;
}
:global(.tippy-box) .reference .version {
  color: #333;
  font-size: 80%;
}
:global(.tippy-box) .title {
  background-color: #651300;
  color: white;
  text-align: center;
  line-height: 25px;
  padding-top: 2px;
  height: 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
:global(.tippy-box) .footer {
  padding: 0 1em 0 1em;
  background-color: #651300;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
:global(.tippy-box) .footer a.bg_popup-bglogo {
  background: transparent url(https://www.biblegateway.com/public/link-to-us/tooltips/theme/images/tools/BGLogoWhite.png?v2) no-repeat scroll 0 0;
}
:global(.tippy-box) .footer a.bg_popup-bglogo .text_fallback {
  visibility: hidden;
  text-decoration: none;
  width: 100%;
  display: inline-block;
}

:global(.tippy-box[data-theme~="light"][data-placement^="top"] > .tippy-arrow:before) {
  border-top-color: #651300;
}

:global(.tippy-box[data-theme~="light"][data-placement^="bottom"] > .tippy-arrow:before) {
  border-bottom-color: #651300;
}</style>
