<!-- TODO: filter and only pass through those prefixed with FormField$ -->
<SmuiInputGroup {field} {...$$restProps}>
  <Checkbox
    autofocus
    {...inputPropsForSmuiCheckbox(field.input)}
    on:blur={field.handlers.blur}
    on:focus={field.handlers.focus}
    on:input={field.handlers.change}
    input$value="1"
  />
  <span slot="label">
    <slot name="label" />
  </span>
</SmuiInputGroup>

<script lang="ts">import Checkbox from '@smui/checkbox';
import { SmuiInputGroup } from '@svelte/common/form/svelte-final-form';
import { inputPropsForSmuiCheckbox } from '@svelte/common/form/svelte-final-form';
export let field;
</script>
