import * as React from 'react'

export interface ContactRequestOnFinderProps {
  name: string
  id: string
}

export function ContactRequestOnFinder({ name, id, ...props }: ContactRequestOnFinderProps) {
  return (
    <div className="simple_church contact_request_on_finder">
      <h3 className="name">Connect With {name}</h3>
      <p>
        Please tell us about yourself. This will only be shared with the house church leaders that you wish to connect
        with.
      </p>
      <form
        className="simple_form form-horizontal contact-request-form"
        style={{ marginTop: '1em' }}
        action={`/house_churches/${id}/contact_requests/new`}
        acceptCharset="UTF-8"
        method="get"
      >
        <fieldset>
          <div className="control-group string required contact_request_from_user_given_name">
            <label className="string required control-label" htmlFor="contact_request_from_user_attributes_given_name">
              First Name{' '}
              <abbr className="required" title="Required">
                *
              </abbr>
            </label>
            <div className="controls">
              <input
                className="string required"
                required={true}
                aria-required="true"
                placeholder="John"
                type="text"
                name="contact_request[from_user_attributes][given_name]"
                id="contact_request_from_user_attributes_given_name"
              />
            </div>
          </div>
          <div className="control-group string required contact_request_from_user_surname">
            <label className="string required control-label" htmlFor="contact_request_from_user_attributes_surname">
              Last Name{' '}
              <abbr className="required" title="Required">
                *
              </abbr>
            </label>
            <div className="controls">
              <input
                className="string required"
                required={true}
                aria-required="true"
                placeholder="Doe"
                type="text"
                name="contact_request[from_user_attributes][surname]"
                id="contact_request_from_user_attributes_surname"
              />
            </div>
          </div>
          <div className="control-group string required contact_request_from_user_email">
            <label className="string required control-label" htmlFor="contact_request_from_user_attributes_email">
              E-mail address{' '}
              <abbr className="required" title="Required">
                *
              </abbr>
            </label>
            <div className="controls">
              <input
                className="string required"
                required={true}
                aria-required="true"
                placeholder="john.doe@example.com"
                type="text"
                name="contact_request[from_user_attributes][email]"
                id="contact_request_from_user_attributes_email"
              />
            </div>
          </div>
          <div style={{ paddingLeft: '180px' }}>
            <button className="btn btn-primary btn-large" type="submit">
              Continue »
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  )
}

/*
</div>

// app/views/house_churches/_contact_request_on_finder_jquery_tmpl.html.haml
%script#contact_request_on_finder_template{type: 'text/x-jquery-tmpl'}
  .contact_request_on_finder
    %h3.name Connect With ${name}

    %p
      Please tell us about yourself. This will only be shared with the house
      church leaders that you wish to connect with.

    -# This is a mini version of app/views/contact_requests/new.html.haml
    = simple_form_for ContactRequest.new,
      url:      new_house_church_contact_request_path('${id}'), method: :get,
      html:     { class: "form-horizontal contact-request-form", id: nil, style: "margin-top:1em" },
      defaults: { label_html: { class: "blue_bold" } } do |f|

      = f.fields_for :from_user, User.new do |uf|
        %fieldset
          = uf.input :given_name, required: true
          = uf.input :surname,    required: true
          = uf.input :email,      required: true, as: :string
          %div(style="padding-left: 180px;")
            %button.btn.btn-primary.btn-large(type="submit")
              Continue »
*/
