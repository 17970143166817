import * as React from 'react'

export interface HouseChurchBaseSearchResultProps {
  icon_file_name: string
  name: string
  state: string
  human_state: string
  address?: any
  default_conference?: any
  leader_names_for_search?: string
  coach?: any

  containerClass: string
  imgAlt: string
}

export function HouseChurchBaseSearchResult({ containerClass, imgAlt, ...props }: HouseChurchBaseSearchResultProps) {
  return (
    <div className={`house_church_base ${containerClass} search_result clear_left`}>
      <div className="icon">
        <img alt={`${imgAlt}`} height="29" src={`/assets/${props.icon_file_name}`} width="33" />
      </div>
      <div className="title float_left">
        {props.name}
        {props.human_state && (
          <span>
            —<span className="{props.state} house_church_state">{props.human_state}</span>
          </span>
        )}
      </div>
      {typeof props.address == 'object' && (
        <div className="city_state_country float_left">{props.address.city_state_country}</div>
      )}

      {props.default_conference ? (
        <div className="default_conference float_left">{props.default_conference.name}</div>
      ) : (
        <div className="default_conference float_left">Simple Church Global Network</div>
      )}

      {props.leader_names_for_search && (
        <div className="leader_names_for_search float_left">{props.leader_names_for_search}</div>
      )}
      {props.coach && <div className="coach float_left">Coach: {props.coach.name}</div>}
      <br className="clear" />
    </div>
  )
}
