<button
  use:popperRef
  on:click={() => (showTooltip = !showTooltip)}
  on:xmouseenter={() => (showTooltip = true)}
  on:xmouseleave={() => (showTooltip = false)}
>
  My button
</button>
{#if showTooltip}
  <div id="tooltip" use:popperContent={popperOptions}>
    <div id="arrow" data-popper-arrow />
    <Paper>
      <Content>
        <p>Many, many</p>
        <p>many, many</p>
        <p>lines</p>
        <p>lines</p>
        <p>in this popover.</p>
        <p>(To test that it shows up over top of the footer.)</p>
      </Content>
    </Paper>
  </div>
{/if}

<script>
  // Based on:
  // - https://github.com/bryanmylee/svelte-popperjs#example
  // - https://popper.js.org/docs/v2/tutorial/ (for the CSS)
  import Paper, { Title, Subtitle, Content } from '@smui/paper'
  import { createPopperActions } from 'svelte-popperjs'
  const [popperRef, popperContent] = createPopperActions()
  const popperOptions = {
    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
  }

  let showTooltip = false
</script>

<style>
  /*
  #tooltip {
    background: #333;
    color: white;
    font-weight: bold;
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 4px;
  }
  */

  #arrow,
  #arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: -1;
  }

  #arrow::before {
    content: '';
    transform: rotate(45deg);
    background: #ddd;
  }

  :global(#tooltip[data-popper-placement^='top']) > #arrow {
    bottom: -4px;
  }

  :global(#tooltip[data-popper-placement^='bottom']) > #arrow {
    top: -4px;
  }

  :global(#tooltip[data-popper-placement^='left']) > #arrow {
    right: -4px;
  }

  :global(#tooltip[data-popper-placement^='right']) > #arrow {
    left: -4px;
  }
</style>
