<Form {onSubmit} let:form let:state>
  <form on:submit|preventDefault={form.submit}>
    <div>
      <Field name="house_church_registration.phone" validate={(value) => value || 'is required'} let:field>
        <!-- <pre>meta: {JSON.stringify(meta, null, 2)}</pre> -->
        <!-- <p>phoneCountry: {phoneCountry}</p> -->
        <div>
          <PhoneInput
            {...field.input}
            onBlur={field.handlers.blur}
            onFocus={field.handlers.focus}
            onChange={(value) => field.handlers.change(value)}
            bind:country={phoneCountry}
            id={field.input.name}
          />
        </div>
        <FieldErrors {field} />
      </Field>
    </div>

    <button type="submit" disabled={state.submitting}>Submit</button>
  </form>

  <pre>state.values: {JSON.stringify(state.values, null, 2)}</pre>
  <pre>state: {JSON.stringify(state, null, 2)}</pre>
</Form>

<script>
  import { Form, Field } from 'svelte-final-form'
  import PhoneInput from '@svelte/common/form/fields/PhoneInput.svelte'
  import { InputGroup, FieldErrors, FormSubmissionErrors } from '@svelte/common/form/svelte-final-form/'

  const onSubmit = (values) => {
    console.log(JSON.stringify(values, undefined, 2))
  }

  let phoneCountry
</script>
