params:
<pre>{JSON.stringify(params, null, 2)}</pre>

<RailsForm action={window.location.pathname} method="get">
  <div>
    <input placeholder="Required field" required="required" type="text" name="text" value={params.text} />
    <input required="required" type="number" name="delay" value={params.delay || 1} />
  </div>
  <ContinueButton bind:formEnabled>Continue »</ContinueButton>
  <div>
    <button disabled={!$formEnabled} on:click={() => ($formEnabled = false)}>Disable</button>
    <button disabled={$formEnabled} on:click={() => ($formEnabled = true)}>Re-enable</button>
  </div>
</RailsForm>

<script>
  import { RailsForm } from '@svelte/common/form'
  import ContinueButton from '@svelte/invitation_response/ContinueButton.svelte'

  export let params = undefined
  let formEnabled
</script>

<style>
  :global(form) {
    border: 1px solid darkgray;
    margin-bottom: 1em;
  }
</style>
