<div class="intro mb-2">
  <p>During January, a CORE team can select a new coach. <em>This is optional. It is not required.</em></p>
  <p>
    Coaching is what decentralizes our network so that it can expand exponentially. This is one key difference between a
    top-down institutional church and a grass-roots organic movement that is 100% lay-led.
  </p>
  <p>
    Different coaches have various personalities, life experiences, strengths and weaknesses, and talents — as we all
    do.
  </p>
  <p>
    Prayerfully talk about this as a CORE team. If a new coach is selected, there is no way to reverse this decision.
    Again, selecting a new coach is optional.
  </p>

  <FormField class="radio">
    <Radio name="confirmation" value="keep_current" bind:group={confirmation} on:input />
    <div slot="label">We want to continue with our <em>current coach</em>, {saved_coach.name}.</div>
  </FormField>
  {#if confirmation == 'keep_current'}
    <FormField>
      <!-- Wrapped in a FormField and using an invisible Radio just to get the right column of text to line up with the label of the actual visible Radio.
        You might think that this would be as simple as just moving this text to the label above, and setting align-items: top to make the Radio top-aligned, but without knowing/hard-coding the exact pixel height of the Radio, that approach unfortunately didn't work as well as I wanted. -->
      <Radio class="invisible" />
      <div slot="label" style="margin-top: 5px">
        <p>
          You're all set! {saved_coach.given_name} will continue to be your coach for the rest of {year}.
        </p>
      </div>
    </FormField>
  {/if}

  <FormField class="radio">
    <Radio name="confirmation" value="pick_a_new_coach" bind:group={confirmation} on:input />
    <div slot="label">We would like to pick a <em>new coach</em>.</div>
  </FormField>
  {#if confirmation == 'pick_a_new_coach'}
    <FormField>
      <Radio class="invisible" />
      <div slot="label" style="margin-top: 5px">
        <p>
          This change needs to happen by January 31, {year}.
        </p>
      </div>
    </FormField>
  {/if}

  <div class="indented" />
  <p style="margin-top: 14px">
    May God's blessings be upon you as you accept God's invitations to join Him in His missionary work in {year}.
  </p>
  <p>People need Jesus. And Jesus invites you to help Him reach those who are far from Him.</p>
  <p>May we press on.</p>
  <img src={miltonSignatureImage} alt="*" />
</div>

<script lang="ts">import FormField from '@smui/form-field';
import Radio from '@smui/radio';
import 'common/types';
// Which coach is currently saved in database
export let saved_coach;
const miltonSignatureImage = require('images/milton_signature.png');
const year = new Date().getFullYear();
export let confirmation = undefined;
</script>

<style lang="scss">.indented {
  margin-left: 2rem;
}

:global(.mdc-form-field) {
  font-size: 1rem;
  display: flex;
}

:global(.mdc-form-field.radio) {
  font-size: 1.1rem;
}</style>
