<!-- Based on the simple example from https://github.com/hperrin/smui-example-webpack.git -->
<div class="container">
  <Button on:click={() => clicked++}>
    <Icon class="material-icons">thumb_up</Icon>
    <Label>Click Me</Label>
  </Button>
  <p class="mdc-typography--body1">
    {#if clicked}
      You've clicked the button
      {clicked}
      time{clicked === 1 ? '' : 's'}.
    {:else}<span class="grayed">You haven't clicked the button.</span>{/if}
  </p>
</div>

<script>
  import Button, { Label, Icon } from '@smui/button'

  let clicked = 0
</script>

<!-- 
Moved to <head> to prevent FOUC
<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700" />
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto+Mono" />
-->
<style>
  .grayed {
    opacity: 0.6;
  }
</style>
