import * as React from 'react'
import { HouseChurchBaseSearchResultProps, HouseChurchBaseSearchResult } from './HouseChurchBaseSearchResult'

export interface HouseChurchSearchResultProps {
  icon_file_name: string
  name: string
  state: string
  human_state: string
  address?: any
  leader_names_for_search?: string
}

// This is used for showing *non-network* simple churches in the search results
// See also app/javascript/@react/search/SimpleChurchSearchResult.tsx
export function HouseChurchSearchResult(props: HouseChurchSearchResultProps) {
  return <HouseChurchBaseSearchResult {...props} containerClass="house_church" imgAlt="House church" />
}
