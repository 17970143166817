<form acceptcharset="UTF-8" {method} {...$$restProps} bind:this={formEl} on:submit>
  {#if method !== 'GET'}
    <AuthenticityToken />
    <input type="hidden" name="_method" value={actual_method} />
  {/if}
  <slot />
</form>

<script>
  import { onMount } from 'svelte'
  import AuthenticityToken from '@svelte/common/form/RailsForm/AuthenticityToken.svelte'
  // TODO: forward/bubble all events, not just on:submit

  export let formEl = undefined
  let supplied_method = 'POST'
  export { supplied_method as method }
  let actual_method = supplied_method.toUpperCase()
  let method = actual_method === 'GET' ? 'GET' : 'POST'

  onMount(() => {
    // console.log('RailsForm', formEl, formEl.querySelector('input'))
  })
</script>
