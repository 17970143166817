<span>{ref}</span>
<BibleRef {ref} />

<script>
  import BibleRef from '@svelte/common/BibleRef.svelte'

  export let ref
</script>

<style>
  span {
    display: inline-block;
    width: 50%;
  }
</style>
