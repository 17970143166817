import * as React from 'react'
import { forwardRef } from 'react'

import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'

import withValueFromTarget from './withValueFromTarget'
import withFocusMethod from './withFocusMethod'
import withFormattedPhoneNumber from './withFormattedPhoneNumber'
// import { RenderCount } from './RenderCount'

// In order to adapt a regular input component to be compatible with the slightly odd API required of components passed to inputComponent prop of PhoneInput (http://catamphetamine.github.io/react-phone-number-input/docs/styleguide/index.html#phoneinput),
// this wraps the component with withFocusMethod and withValueFromTarget
export function adaptMuiInputForPhoneInputSimplest(Wrapped) {
  Wrapped = withValueFromTarget(Wrapped)
  return React.memo(
    withFocusMethod(
      forwardRef<any, any>(function ({ onChange, ...rest }, ref) {
        return (
          <>
            <Wrapped {...rest} onChange={onChange} ref={ref} />
            {/* val: {rest.value} */}
            {/* <RenderCount /> */}
          </>
        )
      })
    )
  )
}

export const MuiInputForPhoneInputSimplest = adaptMuiInputForPhoneInputSimplest(Input)
export const MuiTextFieldForPhoneInputSimplest = adaptMuiInputForPhoneInputSimplest(TextField)

//══════════════════════════════════════════════════════════════════════════════════════════

// In order to adapt a regular input component to be compatible with the slightly odd API required of components passed to inputComponent prop of PhoneInput (http://catamphetamine.github.io/react-phone-number-input/docs/styleguide/index.html#phoneinput),
// this wraps the component with withFocusMethod, withFormattedPhoneNumber, and withValueFromTarget
// TODO: May be able to use numberInputComponent instead and not use this adapter? (https://github.com/catamphetamine/react-phone-number-input/issues/214#issuecomment-542901659)
export function adaptMuiInputForPhoneInput(Wrapped) {
  Wrapped = withFormattedPhoneNumber(withValueFromTarget(Wrapped))
  return withFocusMethod(
    forwardRef<any, any>(function (props, ref) {
      return (
        <>
          <Wrapped {...props} />
          {/* props: {JSON.stringify(Object.keys(props), null, 2)} */}
        </>
      )
    })
  )
}

export const MuiInputForPhoneInputBasic = adaptMuiInputForPhoneInput(Input)
export const MuiInputForPhoneInput = MuiInputForPhoneInputBasic
