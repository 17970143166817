import * as React from 'react'

// Extracts the event.target.value from Wrapped component's onChange and emits just that value to
// the consuming component's onChange handler. This adapter is needed for libraries that accept
// an inputComponent but expect that inputComponent's to only pass a simple string value to its
// onChange handler.
export default function withValueFromTarget(Wrapped) {
  return ({ onChange, ...rest }) => {
    return (
      <Wrapped
        {...rest}
        onChange={({ target: { value } }) => {
          // console.log(typeof value, value)
          onChange(value)
        }}
      />
    )
  }
}
