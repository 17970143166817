<!-- svelte-ignore a11y-click-events-have-key-events -->

<!-- 
<Paper class="root">
<Content>
-->

<RailsForm action={url} on:submit={(event) => onSubmit(event, form)} bind:formEl>
  {#if utils.debug || utils.dev}
    <!-- svelte-ignore a11y-missing-attribute -->
    <p class="debug">
      <a
        role="button"
        tabindex="0"
        on:click={() => {
          debugFillIn(form, formEl)
        }}
      >
        Fill in this form for me (for testing)
      </a>
    </p>
  {/if}

  <p>
    <span class="blue_bold"
      >As part of this global network, I share this Missionary Commitment with {missionary_count} Simple Church missionaries
      around the world.</span
    >
  </p>

  {#if invitation_type === 'supportive_pastor'}
    <p>
      <span class="blue_bold">This is the commitment that each CORE4 missionary makes each year.</span>
      (This obviously would not apply to you as a supportive pastor.)
    </p>
  {/if}

  <Field
    name="missionary_commitment_page[checkbox_items][gather_each_week]"
    type="checkbox"
    let:field
    validate={ifValidationsEnabled(requiredCheckbox)}
  >
    <RequiredCheckbox {field}>
      <span slot="label">
        As a Simple Church missionary, I play an essential role in our simple church and
        <span class="disabled-blue_bold">give top priority to gathering each week with our simple church</span>.
        Occasionally I may be out of town for weddings, funerals, and vacations.
      </span>
    </RequiredCheckbox>
  </Field>

  {#if is_core_leader}
    <Field
      name="missionary_commitment_page[checkbox_items][missionary_work]"
      type="checkbox"
      let:field
      validate={ifValidationsEnabled(requiredCheckbox)}
    >
      <RequiredCheckbox {field}>
        <span slot="label">
          <span class="disabled-blue_bold">I recognize that my Simple Church missionary work will take time.</span>
          To free up my time for service, hospitality, and building authentic relationships, I will need to decline conventional
          church appointments and responsibilities. This will also help my simple church realize that I consider them to
          be my real church family.
        </span>
      </RequiredCheckbox>
    </Field>
  {/if}

  {#if is_core_leader}
    <div class="is_core_leader">
      <p>Keeping our network focused and simple:</p>

      <div class="content">
        <Field
          name="missionary_commitment_page[checkbox_items][lay_led]"
          type="checkbox"
          let:field
          validate={ifValidationsEnabled(requiredCheckbox)}
        >
          <RequiredCheckbox {field}>
            <div slot="label">
              <p>Our simple church is 100% lay-led.</p>
              <p class="mb-0 explanation">
                (Meaning — no person is paid a stipend, assigned/designated by church leadership, or called "Pastor".)
              </p>
            </div>
          </RequiredCheckbox>
        </Field>

        <Field
          name="missionary_commitment_page[checkbox_items][meets_on_sabbath]"
          type="checkbox"
          let:field
          validate={ifValidationsEnabled(requiredCheckbox)}
        >
          <RequiredCheckbox {field}><span slot="label"> Our simple church meets on Sabbath. </span></RequiredCheckbox>
        </Field>

        <!-- 
        TODO: fix sff so we can just have a single Field for radio instead of a "Field" for each value
        <Field name="simple_church[meets_in]" type="radio" let:field validate={ifValidationsEnabled(requiredCheckbox)}>
          <SmuiInputGroup {field} wrapper2={Div}>
            <p style="margin-bottom: 0;">
              Which describes your simple church best? Choose one.
            </p>
            <FormField>
              <Radio name="checkbox_1" bind:group={meets_in} value={'personal_home'} disabled={false} />
              <span slot="label">
                Our simple church meets in personal homes.
              </span>
            </FormField>
            <FormField>
              <Radio name="checkbox_1" bind:group={meets_in} value={'3rd_space'} disabled={false} />
              <span slot="label">
                Our simple church usually meets in a public space. We do not meet in a personal home.
              </span>
            </FormField>
          </SmuiInputGroup>
        </Field>
-->

        <!-- {#if !meets_in_already_set} -->
        <div class="InputGroup">
          <p style="margin-bottom: 0;">Which describes your simple church best? Choose one.</p>
          <Field name="simple_church[meets_in]" value={'personal_home'} type="radio" let:field>
            <FormField>
              <Radio
                {...inputPropsForSmuiRadio(field)}
                on:blur={field.handlers.blur}
                on:focus={field.handlers.focus}
                on:input={field.handlers.change}
              />
              <span slot="label"> Our simple church meets in personal homes. </span>
            </FormField>
          </Field><br />
          <Field name="simple_church[meets_in]" value={'3rd_space'} type="radio" let:field>
            <FormField>
              <Radio
                {...inputPropsForSmuiRadio(field)}
                on:blur={field.handlers.blur}
                on:focus={field.handlers.focus}
                on:input={field.handlers.change}
              />
              <span slot="label">
                Our simple church meets in a public space instead of a home. (This provision is made for people who live
                in parts of the word where homes are very, very small or are a shared space among several families.)
              </span>
            </FormField>
          </Field>
          <Field name="simple_church[meets_in]" let:field validate={ifValidationsEnabled(required)}>
            <FieldErrors {field} when="submitFailed" />
          </Field>
        </div>
      </div>
    </div>
  {/if}

  <Field
    name="missionary_commitment_page[checkbox_items][obey_gods_word]"
    type="checkbox"
    let:field
    validate={ifValidationsEnabled(requiredCheckbox)}
  >
    <RequiredCheckbox {field}>
      <span slot="label">
        As a missionary, I will seek to obey God's Word as I continue to grow closer to Jesus and follow Him.
      </span>
    </RequiredCheckbox>
  </Field>

  <Field
    name="missionary_commitment_page[checkbox_items][share]"
    type="checkbox"
    let:field
    validate={ifValidationsEnabled(requiredCheckbox)}
  >
    <RequiredCheckbox {field}>
      <span slot="label">
        As a missionary and Truth-seeker, I will look for other Truth-seekers and share Bible truth with them.
      </span>
    </RequiredCheckbox>
  </Field>

  <Field name="invitation[recipient_attributes][id]" let:field>
    <input type="hidden" {...field.input} id="invitation_recipient_attributes_id" />
  </Field>
  <Field
    name="invitation[recipient_attributes][describe_vision]"
    type="text"
    let:field
    validate={ifValidationsEnabled(required)}
  >
    <SmuiInputGroup {field} class="describe_vision" errorLocation="below">
      <Textfield
        fullwidth
        textarea
        variant="outlined"
        {...inputPropsForSmuiTextfield(field.input)}
        label=""
        on:blur={field.handlers.blur}
        on:focus={field.handlers.focus}
        on:input={field.handlers.change}
      >
        <span slot="label"> Describe the missionary vision God has given to you for your local simple church * </span>
      </Textfield>
    </SmuiInputGroup>
  </Field>

  <ContinueButton>
    {#if viewing_a_standalone_mc_page}Save changes{:else}Continue »{/if}
  </ContinueButton>
</RailsForm>

<!-- 
</Content>
</Paper>
-->

{#if utils.debug || utils.dev}
  <pre class="debug">state.values: {JSON.stringify(state.values, null, 2)}</pre>
  <pre class="debug">state: {JSON.stringify(state, null, 2)}</pre>
{/if}

<script lang="ts">import { onMount } from 'svelte';
import 'svelte-elements';
// final-form
import { createForm, Field } from 'svelte-final-form';
import create_focusOnError from 'final-form-focus';
import { ifValidationsEnabled, changeValues } from 'common/final-form';
const required = (value) => (value && (!Array.isArray(value) || value.length) ? undefined : 'This field is required.');
// "This check box must be checked to continue."
const requiredCheckbox = (value) => value && (!Array.isArray(value) || value.length) ? undefined : 'This must be checked';
// svelte-material-ui
import Textfield from '@smui/textfield';
import '@smui/textfield/helper-text/index';
import Radio from '@smui/radio';
import '@smui/checkbox';
import FormField from '@smui/form-field';
import '@smui/button';
import '@smui/paper';
import { utils } from '../../../common/utils';
import '@svelte/common';
import { RailsForm } from '@svelte/common/form';
import { SmuiInputGroup, FieldErrors } from '@svelte/common/form/svelte-final-form';
import { inputPropsForSmuiTextfield, inputPropsForSmuiRadio, } from '@svelte/common/form/svelte-final-form';
import RequiredCheckbox from './RequiredCheckbox.svelte';
import ContinueButton from '../ContinueButton.svelte';
export let simple_church = {};
export let user = {};
export let step_completed = false;
export let viewing_a_standalone_mc_page;
export let url;
export let is_core_leader = false;
export let invitation_type;
export let missionary_count;
let formEl = undefined;
// { final-form
const onSubmit = (event, form) => {
    // I wish this had a useful (valid/invalid) return value that we could check.
    form.submit();
    // console.log('form.getState().submitFailed', form.getState().submitFailed)
    if (form.getState().submitFailed) {
        event.preventDefault();
    }
    else {
        // Allow form to submit normally to server
    }
};
const onValidSubmit = (values) => {
    // console.log('valid', JSON.stringify(values, undefined, 2))
    // console.log('valid', form.getState())
};
let initialValues = {
    simple_church,
    invitation: { recipient_attributes: user },
};
const filledInValues = {
    missionary_commitment_page: {
        checkbox_items: {
            gather_each_week: true,
            missionary_work: true,
            obey_gods_word: true,
            share: true,
            // Questions asked of first CORE leader about their simple_church
            lay_led: true,
            meets_on_sabbath: true,
        },
    },
};
if (step_completed) {
    initialValues = Object.assign(Object.assign({}, initialValues), filledInValues);
}
const [form, stateStore] = createForm({
    onSubmit: onValidSubmit,
    decorators: [create_focusOnError()],
    initialValues: initialValues,
    mutators: {
        changeValues,
    },
});
$: state = $stateStore;
export const debugFillIn = (form, rootElement) => {
    const formValues = form.getState().values;
    form.mutators.changeValues(Object.assign(Object.assign({}, filledInValues), { simple_church: {
            meets_in: 'personal_home',
        }, invitation: {
            recipient_attributes: Object.assign(Object.assign({}, formValues.invitation.recipient_attributes), { describe_vision: 'My vision is...' }),
        } }));
    const submit = rootElement.querySelector('button[type=submit]');
    // const firstInput = rootElement.querySelector('input')
    submit.focus();
};
// } final-form
onMount(() => {
    formEl.querySelector('input[type="checkbox"]').focus();
});
</script>

<style lang="scss">:global(.smui-paper) {
  padding: 24px 24px;
}

:global(.InputGroup) {
  margin-bottom: 1em;
}

.is_core_leader .content {
  margin-left: 2em;
}

:global(.mdc-form-field.align-items-start) {
  align-items: start;
}
:global(.mdc-form-field) :global(div.mdc-checkbox) {
  margin-top: -9px;
}
:global(.mdc-form-field) :global(label) {
  margin-bottom: unset;
}

:global(.InputGroup.describe_vision) {
  margin: 2em 0;
}
:global(.InputGroup.describe_vision) :global(.mdc-form-field) {
  width: 100%;
}

.explanation {
  font-size: 93%;
  width: 90%;
}</style>
