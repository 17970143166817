<!-- formEnabled: {formEnabled && $formEnabled}; disabled: {disabled} -->
<div class="button_container" {...buttonContainerProps}>
  <!-- <button type="submit" name="commit" class="btn btn-primary btn-large" data-x-disable-with="Submitting..."> -->
  <Button
    type="submit"
    color="primary"
    variant="raised"
    style="min-width: 33%; font-size: 1.2rem;"
    {...{
      ...(disabled === undefined ? {} : { disabled }),
      ...$$restProps,
    }}
    use={[preventDoubleSubmit]}
  >
    <Label>
      <slot />
      {label_text}
    </Label>
  </Button>
</div>

<script lang="ts">import Button, { Label } from '@smui/button';
import { createPreventDoubleSubmit } from '@svelte/common/form/use_preventDoubleSubmit';
import 'svelte';
let preventDoubleSubmit;
export let disabled = undefined; // Force it to be disabled. (formEnabled/preventDoubleSubmit is not used in this case)
export let formEnabled = undefined // store
;
[preventDoubleSubmit, formEnabled] = createPreventDoubleSubmit(!disabled);
export let buttonContainerProps = {};
export let label_text = '';
</script>

<style lang="scss">:global(.mdc-button) {
  text-transform: none;
}

.button_container {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}</style>
