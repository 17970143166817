import * as React from 'react'

declare global {
  interface Window {
    user_icon_path: any
  }
}

export interface UserSearchResultProps {
  name: string
  phone?: string
  email?: string
  skype_name?: string

  address?: any
  simple_church?: any
  conference?: any

  status: string
  simple_church_roles_human_string: string
}

export function UserSearchResult(props: UserSearchResultProps) {
  return (
    <div className="user search_result clear_left">
      <div className="float_right">
        {props.phone && <div className="phone">{props.phone}</div>}
        {props.email && <div className="email">{props.email}</div>}
        {props.skype_name && <div className="skype_name">{props.skype_name}</div>}
      </div>

      <div className="icon">
        <img alt="User" src={window.user_icon_path} width="60" height="53" />
      </div>
      <div className="title float_left">{props.name}</div>

      {typeof props.address == 'object' && (
        <div className="city_state_country float_left">{props.address.city_state_country}</div>
      )}
      {typeof props.simple_church == 'object' && (
        <div className="simple_church_name float_left">{props.simple_church.name}</div>
      )}

      {(props.status || props.simple_church_roles_human_string) && (
        <div className="status_and_role float_left">
          {props.simple_church_roles_human_string}
          {props.status && <span className="status">({props.status})</span>}
        </div>
      )}

      {typeof props.conference == 'object' && <div className="conference float_left">{props.conference.name}</div>}

      <br className="clear" />
    </div>
  )
}
