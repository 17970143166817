import Test_smui_Typography from './Typography.svelte'
import Test_smui_Dialog from './Dialog.svelte'
import Test_smui_Textfield from './Textfield.svelte'
import Test_smui_MenuSurface from './MenuSurface.svelte'

export default {
  Test_smui_Typography,
  Test_smui_Dialog,
  Test_smui_Textfield,
  Test_smui_MenuSurface,
}
