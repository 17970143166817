import Test_BibleGatewayReferenceTool from './BibleGatewayReferenceTool.svelte'
import Test_BibleRef from './BibleRef.svelte'
import Test_SassParentSelector from './Test_SassParentSelector.svelte'
import Test_LanguageSelect from './LanguageSelect.svelte'
import Test_Popover from './Popover.svelte'
import Test_SveltePopper from './SveltePopper.svelte'
import Test_Tippy from './Tippy/App.svelte'
import TestContinueButton from './TestContinueButton.svelte'
import TestMessage from './TestMessage.svelte'
import TestRailsForm from './TestRailsForm.svelte'

import test_svelte_material_ui from './svelte-material-ui'

import Test_FinalForm_PhoneInput from './svelte-final-form/PhoneInput.svelte'
import TestFinalForm1 from './svelte-final-form/Test1.svelte'
import TestFinalFormArrays from './svelte-final-form/TestFinalFormArrays.svelte'

export default {
  Test_BibleGatewayReferenceTool,
  Test_BibleRef,
  Test_SassParentSelector,
  Test_LanguageSelect,
  Test_Popover,
  Test_SveltePopper,
  Test_Tippy,
  TestContinueButton,
  TestMessage,
  TestRailsForm,

  ...test_svelte_material_ui,

  Test_FinalForm_PhoneInput,
  TestFinalForm1,
  TestFinalFormArrays,
}
