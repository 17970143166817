import { Writable, writable } from 'svelte/store'

type Button = HTMLButtonElement | HTMLInputElement

// Automatically handles disabling submit button on the form when form is submitted.
//
// If you use this on a submit button, then only that submit button will get disabled.
//
// If you want to re-enable submit buttons later, use createPreventDoubleSubmit,
// which (in addition to returning preventDoubleSubmit) returns a writable store
// that you can set to true to re-enable.
export function preventDoubleSubmit(
  el,
  {
    disabledText,
    enabledStore,
  }: {
    disabledText?: string
    enabledStore?: Writable<boolean>
  }
) {
  let initialized = false
  let form: HTMLFormElement
  let event
  let buttons
  if (el.tagName === 'FORM') {
    form = el
    buttons = [...findButtons(form)]
  } else if (el.form) {
    form = el.form
    buttons = [el]
  } else {
    throw new Error(`may only be used on form or element with element.form, but was ${el}`)
  }
  disabledText = disabledText ?? 'Submitting...'
  enabledStore = enabledStore ?? writable<boolean>(true)

  enabledStore.subscribe((enabled) => {
    if (!initialized) {
      return
    }
    // console.log('enabledStore is now', enabled)
    if (enabled) {
      enableButtons()
    } else {
      disableButtons()
    }
  })

  // Find all buttons and disable them
  function disableButtons() {
    for (const el of buttons) {
      disableButton(el)
    }
  }

  function enableButtons() {
    for (const el of buttons) {
      enableButton(el)
    }
  }

  function findButtons(form: HTMLFormElement) {
    return form.querySelectorAll<Button>('input[type="submit"], button')
  }

  function disableButton(el) {
    // console.log('disabling', el)
    if (el.tagName === 'BUTTON') {
      // In case we need to re-enable
      el.dataset.orig_innerText = el.innerText
      el.innerText = disabledText
    } else {
      el.dataset.orig_value = el.value
      el.value = disabledText
    }
    setTimeout(() => (el.disabled = true), 1)
  }

  function enableButton(el) {
    if (el.tagName === 'BUTTON') {
      const orig = el.dataset.orig_innerText
      el.innerText = orig
    } else {
      const orig = (el.dataset.orig_value = el.value)
      el.value = orig
    }
    el.disabled = false
  }

  function handleSubmit(event) {
    // We delay this check/handler slightly to give a chance for any <form
    // on:submit> to call event.preventDefault(). If they _have_ called
    // preventDefault(), then we don't want to disable the submit button because
    // there were probably validation errors that we want to let them correct
    // and then submit the form again.
    // I wish there were a cleaner way to do this. It seems that any submit
    // event listener added by a use: action gets registered/called _before_
    // any listeners added by on:submit. If there were a way to control the
    // order these listeners were registered, and tell this one to get
    // registered _last_ (to give any other handlers a chance to abort the
    // submit with preventDefault()), then we could do it that way more cleanly.
    setTimeout(() => {
      if (!event.defaultPrevented) {
        enabledStore.set(false)
      }
    }, 1)
  }

  form.addEventListener('submit', handleSubmit)

  initialized = true

  return {
    destroy() {
      el.removeEventListener(event, handleSubmit)
    },
  }
}

// Usage:
//   const [preventDoubleSubmit, enabledStore] = createPreventDoubleSubmit()
export function createPreventDoubleSubmit(initialEnabled = true, options = {}) {
  const enabledStore = writable(initialEnabled)
  const custom_preventDoubleSubmit = (el) => {
    preventDoubleSubmit(el, { ...options, enabledStore })
  }
  return [custom_preventDoubleSubmit, enabledStore]
}
