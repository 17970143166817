<div class="intro mb-2">
  {#if simple_church.is_picking_first_coach}
    <p><b>It is time to pick a coach.</b></p>
  {/if}
  <p>
    A coach is someone who has walked this path ahead of you. That does not mean they have all the answers. In Simple
    Church, every CORE team has a coach. In other words, no one does this alone.
  </p>
  <p><b>Your coach will:</b></p>
  <ol class="your_coach_will">
    {#if !simple_church.is_launched}
      <li>Mentor your CORE team through PhaseONE and PhaseTWO training.</li>
    {/if}
    <li>Read and reply to your CORE team’s online weekly report summaries.</li>
    <li>Be available for occasional phone/email help beyond the weekly online summary.</li>
  </ol>
  <p>
    To help you pick a coach, we have compared your profile with over {coach_count} Simple Church coaches and identified
    the best 5 options. Prayerfully consider each one and then choose one from the list below.
  </p>
  <ul>
    <li>
      <b>Optional</b> 👍 — Each coach has strengths and weaknesses. So each year you will have the option (it is not required)
      to pick another coach, if you wish. This makes it possible to benefit from a variety of coaching strengths.
    </li>
    <li>
      <b>Soon it will be your turn</b> 🙂 —
      {#if simple_church.is_launched}
        Now that your simple church has launched,
      {:else}
        Once your CORE team finishes your training and launches,
      {/if}
      each person in your CORE team will be added to the “Pick a Coach” list. And it will be your turn to coach others through
      the same process you are going through.
    </li>
    <li>
      <b>Remember</b> 🎗 — Biblical Truth-seeking is what binds us together. But don’t assume your coach comes from the same
      denominational background as you have. But you can be confident that they have embraced God’s end-time story and end-time
      invitation — what the Bible calls the Everlasting Gospel of Jesus.
    </li>
  </ul>
</div>

<script lang="ts">import 'common/types';
export let simple_church;
export let coach_count;
</script>

<style lang="scss">:global(.mdc-form-field) {
  font-size: 1rem;
  display: flex;
}

:global(.mdc-form-field.radio) {
  font-size: 1.1rem;
}</style>
