class Utils {
  get urlParams() {
    return new URLSearchParams(window.location.search)
  }
  get embedded() {
    // return !!window.location.search.match(/\bembed=1(\b|$)/)
    return this.urlParams.get('embed') == '1'
  }
  get debug() {
    // return !!window.location.search.match(/\bdebug=1(\b|$)/) ||
    return this.urlParams.get('debug') == '1' || localStorage.show_debug_info
  }
  get dev() {
    return window.location.href.match(/\/\/my.dev./) || window.location.href.match(/\/\/my.staging./)
  }

  catchExceptions(block) {
    try {
      return block()
    } catch (exc) {
      return exc
    }
  }

  toSentence = (list: any[]) => list.join(', ')
}
export const utils = new Utils()

export const skipValidations = () => utils.urlParams.get('validate') === '0'
export const ifValidationsEnabled = (value) => (skipValidations() ? false : value)

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const deleteAllProps = (obj) => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      delete obj[prop]
    }
  }
}

export function isObject(o) {
  return o instanceof Object && o.constructor === Object
}

export function transformKeys(source, f) {
  return Object.entries(source).reduce((o, [key, value]) => {
    o[f(key) || key] = value
    return o
  }, {})
}

export function rekeyObject(source, keyMap) {
  transformKeys(source, (key) => keyMap[key])
}
